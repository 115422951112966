import {Route} from '@angular/router';
import {CompletePgEmailOtpComponent} from './complete-pg-email-otp.component';

export const completePgEmailOtpRoute: Route = {
    path: 'complete-pg-email-otp',
    component: CompletePgEmailOtpComponent,
    data: {
        pageTitle: 'login.title'
    }
};
