import {TokenType} from '@core/model/api/token.model';

export interface IPropertyType {
    id?: number;
    name?: string;
    uuid?: string;
    secure?: boolean;
    tokenType?: TokenType;
}

export class PropertyType implements IPropertyType {
    constructor(
        public id?: number,
        public name?: string,
        public uuid?: string,
        public secure?: boolean,
        public tokenType?: TokenType,
    ) {
        this.secure = this.secure || false;
    }
}
