import {IPropertyType} from '@core/model/api/property-type.model';

export interface IRecordType {
    id?: number;
    name?: string;
    key?: string;
    localizationProperty?: string;
    localizationRegexp?: string;
    changeRequestIdProperty?: string;
    propertyTypes?: IPropertyType[];
}

export class RecordType implements IRecordType {
    constructor(
        public id?: number,
        public name?: string,
        public key?: string,
        public localizationProperty?: string,
        public localizationRegexp?: string,
        public changeRequestIdProperty?: string,
        public propertyTypes?: IPropertyType[],
    ) {
        this.propertyTypes = this.propertyTypes || [];
    }
}
