import {
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faArrowLeft,
    faAsterisk,
    faBan,
    faBars,
    faBell,
    faBook,
    faCalendar,
    faCalendarAlt,
    faCheck,
    faChevronRight,
    faClock,
    faCloud,
    faCode,
    faCodeBranch,
    faDesktop,
    faExclamation,
    faExclamationTriangle,
    faEye,
    faFilter,
    faFlag,
    faHdd,
    faHeart,
    faHome,
    faIdCard,
    faInfo,
    faInfoCircle,
    faList,
    faListOl,
    faMinus,
    faPencilAlt,
    faPlus,
    faRoad,
    faSave,
    faSearch,
    faSignInAlt,
    faSignOutAlt,
    faSort,
    faSortDown,
    faSortUp,
    faStar,
    faSync,
    faTachometerAlt,
    faTasks,
    faThList,
    faThumbsDown,
    faThumbsUp,
    faTimes,
    faTrash,
    faTrashAlt,
    faTrashRestoreAlt,
    faUser,
    faUserPlus,
    faUsers,
    faWrench
} from '@fortawesome/free-solid-svg-icons';

export const fontAwesomeIcons = [
    faUser,
    faSort,
    faSortUp,
    faSortDown,
    faSync,
    faEye,
    faBan,
    faTimes,
    faArrowLeft,
    faSave,
    faPlus,
    faPencilAlt,
    faBars,
    faThList,
    faUserPlus,
    faRoad,
    faTachometerAlt,
    faHeart,
    faList,
    faBell,
    faBook,
    faHdd,
    faFlag,
    faWrench,
    faClock,
    faCloud,
    faSignOutAlt,
    faSignInAlt,
    faCalendarAlt,
    faSearch,
    faTrashAlt,
    faAsterisk,
    faTasks,
    faHome,
    faUsers,
    faStar,
    faListOl,
    faFilter,
    faCode,
    faCodeBranch,
    faIdCard,
    faDesktop,
    faChevronRight,
    faCheck,
    faCalendar,
    faMinus,
    faThumbsUp,
    faThumbsDown,
    faTrashRestoreAlt,
    faTrash,
    faInfo,
    faExclamationTriangle,
    faExclamation,
    faInfoCircle,
    faAngleDoubleLeft,
    faAngleDoubleRight
];
