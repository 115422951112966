import {IStage} from '@core/model/api/stage.model';
import {IConfigurationVersion} from '@core/model/api/configuration-version.model';

export interface IProject {
    id?: number;
    name?: string;
    stages?: IStage[];
    configurationVersions?: IConfigurationVersion[];
    projectGroupName?: string;
    projectGroupId?: number;
}

export class Project implements IProject {
    constructor(
        public id?: number,
        public name?: string,
        public stages?: IStage[],
        public configurationVersions?: IConfigurationVersion[],
        public projectGroupName?: string,
        public projectGroupId?: number
    ) {}
}
