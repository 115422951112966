import {Authority, Holder, HolderType} from "@core/model/api/holder";
import {replaceEmptyWithNull} from "@core/service/util";

export class Account {
    constructor(
        public uuid?: string,
        public activated?: boolean,
        public email?: string,
        public firstName?: string,
        public lastName?: string,
        public langKey?: string,
        public login?: string,
        public imageUrl?: string,
        public holder?: Holder,
        public holderDataList?: Holder[],
        public holderDataLookup?: Record<number, Holder>,
        public protectedUser?: boolean,
        public otpEmail?: string,
        public exemptFromPgEmailOtp?: boolean,
    ) {}

    getHome(): any[] {
        switch (this.holder?.holderType ?? HolderType.Global) {
            case HolderType.ProjectGroup:
                return ['project-group', this.holder.id];
            case HolderType.Project:
                return [
                    'project-group', this.holder.parent?.id ?? this.holder.parent,
                    'project', this.holder.id
                ];
            default:
                return [''];
        }
    }

    subsetForUpdate() {
        return {
            firstName: replaceEmptyWithNull(this.firstName),
            lastName: replaceEmptyWithNull(this.lastName),
            email: replaceEmptyWithNull(this.email),
            imageUrl: replaceEmptyWithNull(this.imageUrl),
            langKey: replaceEmptyWithNull(this.langKey),

            otpEmail: replaceEmptyWithNull(this.otpEmail),
        };
    }
}

function enrich(set: Set<string>, toAdd: string[]): Set<string> {
    for (const x of toAdd) {
        if (x == '*:*') {
            set.clear();
            set.add(x);
            break;
        }
        if (x.endsWith(':*')) {
            let prefix = x.substr(0, x.length - 1);
            let toDelete = [];
            for (const y of set) if (y.startsWith(prefix)) toDelete.push(y);
            for (const y of toDelete) set.delete(y);
        }
        set.add(x);
    }
    return set;
}

const subjectToPartialApplicationPrefixes = ['*:', 'record:', 'record-change-request:'];

export function isSubjectToPartialApplication(privilege: string) {
    return subjectToPartialApplicationPrefixes.some(pfx => String(privilege).startsWith(pfx));
}

export function convertAccount(a: any): Account {
    let list: Holder[] = [];
    let lookup: Record<number, Holder> = {};
    a.holders.forEach(x => {
        let h = new Holder(
            x.id,
            HolderType[String(x.holderType)],
            undefined,
            x.uuid,
            x.name,
            undefined,
            x.selfServicePasswordRecovery,
            x.pgEmailOtpEnabled,
            x.authzFlowMode,
            enrich(new Set<string>(), x.effectivePrivileges),
            new Set<string>(),
        );
        list.push(h);
        lookup[x.id] = h;
        h.parent = lookup[x.parentId];
        if (!h.isClass1Holder())
            enrich(h.parent.partialPrivileges, x.effectivePrivileges.filter(ep => isSubjectToPartialApplication(ep)));
        h.authorities = x.authorities.map(y => new Authority(y.uuid, y.roleTemplate, h));
    });
    return new Account(
        a.uuid,
        a.activated,
        a.email,
        a.firstName,
        a.lastName,
        a.langKey,
        a.login,
        a.imageUrl,
        lookup[a.holderId],
        list,
        lookup,
        a.protectedUser,
        a.otpEmail,
        a.exemptFromPgEmailOtp,
    );
}

export function checkYourPrivilege(privilegeSet: Set<string> | undefined, privilege: string) {
    if (!privilegeSet || !privilege) return false;
    return privilegeSet.has('*:*')
        || privilegeSet.has(privilege.replace(/:.*$/, ':*'))
        || privilegeSet.has(privilege);
}
