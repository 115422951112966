import './vendor';
import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {CoreModule} from '@core/core.module';
import {SharedModule} from '@shared/shared.module';

import {NgxWebstorageModule} from 'ngx-webstorage';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {DashboardModule} from './dashboard/dashboard.module';
import {MonoAccountModule} from './account/account.module';
import {
    AuthExpiredInterceptor,
    AuthInterceptor,
    ErrorHandlerInterceptor,
    NotificationInterceptor
} from './blocks/interceptor';

@NgModule({
    imports: [
        BrowserModule,
        SharedModule,
        CoreModule,
        NgxWebstorageModule.forRoot({ prefix: 'app', separator: '-' }),
        MonoAccountModule,
        DashboardModule,
        AppRoutingModule
    ],
    declarations: [AppComponent],

    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthExpiredInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorHandlerInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: NotificationInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
