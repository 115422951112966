import {Injectable} from '@angular/core';
import {Account} from "@core/model/api";

import {AccountService} from '@core/service/auth/account.service';
import {AuthServerProvider} from '@core/service/auth/auth-jwt.service';

export class Credentials {
    constructor(
        public login?: string,
        public password?: string,
        public rememberMe?: boolean,
        public cookiesDisabled?: boolean,
    ) {}
}

export type LoginResult = { authnComplete: boolean, account: Account };

@Injectable({providedIn: 'root'})
export class LoginService {
    constructor(
        private accountService: AccountService,
        private authServerProvider: AuthServerProvider,
    ) {}

    login(credentials: Credentials, callback?) {
        return new Promise<LoginResult>((resolve, reject) =>
            this.authServerProvider.login(credentials).subscribe(
                ({authnComplete}) => this.handleAuthnSuccess(authnComplete, resolve, callback),
                err => this.handleAuthnFailure(err, reject, callback),)
        );
    }

    completePgEmailOtp(otp: string, callback?) {
        return new Promise<LoginResult>((resolve, reject) =>
            this.authServerProvider.completePgEmailOtp(otp).subscribe(
                ({authnComplete}) => this.handleAuthnSuccess(authnComplete, resolve, callback),
                err => this.handleAuthnFailure(err, reject, callback),)
        );
    }

    resendPgEmailOtp() {
        return new Promise<boolean>((() => this.authServerProvider.resendPgEmailOtp().subscribe()));
    }

    private handleAuthnSuccess(authnComplete, resolve: (AuthnResult) => void, callback?) {
        if (authnComplete)
            this.accountService.identity(true).then(account => resolve({authnComplete: authnComplete, account}));
        else resolve({authnComplete: authnComplete, account: null});
        if (callback) callback();
    }

    private handleAuthnFailure(err, reject: (reason?: any) => void, callback?) {
        this.logout();
        reject(err);
        if (callback) callback();
    }

    logout() {
        this.authServerProvider.logout().subscribe();
        this.accountService.authenticate(null);
    }
}
