export const enum TokenType {
    STRING = 'STRING',
    EMAIL = 'EMAIL'
}

export interface IToken {
    id?: number;
    token?: string;
    value?: string;
    tokenType?: TokenType;
    stageId?: number;
}

export class Token implements IToken {
    constructor(public id?: number, public token?: string, public value?: string, public tokenType?: TokenType, public stageId?: number) {}
}
