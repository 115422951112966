<main role="main" class="ml-0">
    <div class="card mt-3">
        <div class="card-body pt-0 ">
            <h2 appTranslate="settings.title" [translateValues]="{username: account.login}" *ngIf="account">
                User settings for [<b>{{account.login}}</b>]
            </h2>
            <div class="alert alert-success" *ngIf="success" appTranslate="settings.messages.success">
                <strong>Settings saved!</strong>
            </div>
            <form name="form" role="form" (ngSubmit)="save()" #settingsForm="ngForm" *ngIf="account" novalidate>
                <div class="form-group">
                    <label class="form-control-label" for="firstName" appTranslate="settings.form.firstname">
                        First Name</label>
                    <input type="text" class="form-control" id="firstName" name="firstName"
                           placeholder="{{'settings.form.firstname.placeholder' | translate}}"
                           [(ngModel)]="account.firstName" minlength=1 maxlength=50 #firstNameInput="ngModel">
                    <div *ngIf="firstNameInput.dirty && firstNameInput.invalid">
                        <small *ngIf="firstNameInput.errors.required" class="form-text text-danger"
                               appTranslate="settings.messages.validate.firstname.required"
                        > Your first name is required. </small>
                        <small *ngIf="firstNameInput.errors.minlength" class="form-text text-danger"
                               appTranslate="settings.messages.validate.firstname.minlength"
                        > Your first name is required to be at least 1 character. </small>
                        <small *ngIf="firstNameInput.errors.maxlength" class="form-text text-danger"
                               appTranslate="settings.messages.validate.firstname.maxlength"
                        > Your first name cannot be longer than 50 characters. </small>
                    </div>
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="lastName" appTranslate="settings.form.lastname">
                        Last Name</label>
                    <input type="text" class="form-control" id="lastName" name="lastName"
                           placeholder="{{'settings.form.lastname.placeholder' | translate}}"
                           [(ngModel)]="account.lastName" minlength=1 maxlength=50 #lastNameInput="ngModel">
                    <div *ngIf="lastNameInput.dirty && lastNameInput.invalid">
                        <small *ngIf="lastNameInput.errors.required" class="form-text text-danger"
                               appTranslate="settings.messages.validate.lastname.required"
                        > Your last name is required. </small>
                        <small *ngIf="lastNameInput.errors.minlength" class="form-text text-danger"
                               appTranslate="settings.messages.validate.lastname.minlength"
                        > Your last name is required to be at least 1 character. </small>
                        <small *ngIf="lastNameInput.errors.maxlength" class="form-text text-danger"
                               appTranslate="settings.messages.validate.lastname.maxlength"
                        > Your last name cannot be longer than 50 characters. </small>
                    </div>
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="email" appTranslate="global.form.email">Email</label>
                    <input type="email" class="form-control" id="email" name="email"
                           placeholder="{{'global.form.email.placeholder' | translate}}"
                           [(ngModel)]="account.email" minlength="5" maxlength="254" #emailInput="ngModel" email
                           [required]="account.holder.selfServicePasswordRecovery">
                    <div *ngIf="emailInput.dirty && emailInput.invalid">
                        <small *ngIf="emailInput.errors.required" class="form-text text-danger"
                               appTranslate="global.messages.validate.email.required"
                        > Your email is required. </small>
                        <small *ngIf="emailInput.errors.email" class="form-text text-danger"
                               appTranslate="global.messages.validate.email.invalid"
                        > Your email is invalid. </small>
                        <small *ngIf="emailInput.errors.minlength" class="form-text text-danger"
                               appTranslate="global.messages.validate.email.minlength"
                        > Your email is required to be at least 5 characters. </small>
                        <small *ngIf="emailInput.errors.maxlength" class="form-text text-danger"
                               appTranslate="global.messages.validate.email.maxlength"
                        > Your email cannot be longer than 100 characters. </small>
                    </div>
                </div>
                <div class="form-group" *ngIf="account.holder.pgEmailOtpEnabled && !account.exemptFromPgEmailOtp">
                    <label class="form-control-label" for="otpEmail" appTranslate="global.form.otpEmail">
                        Email for security codes delivery</label>
                    <input type="email" class="form-control" id="otpEmail" name="otpEmail"
                           placeholder="{{'global.form.otpEmail.placeholder' | translate}}"
                           [(ngModel)]="account.otpEmail" minlength="5" maxlength="254" #emailInput="ngModel" email
                           [required]="account.holder.pgEmailOtpEnabled && !account.exemptFromPgEmailOtp && account.holder.selfServicePasswordRecovery"
                    >
                    <div *ngIf="emailInput.dirty && emailInput.invalid">
                        <small *ngIf="emailInput.errors.required" class="form-text text-danger"
                               appTranslate="global.messages.validate.email.required"
                        > Your email is required. </small>
                        <small *ngIf="emailInput.errors.email" class="form-text text-danger"
                               appTranslate="global.messages.validate.email.invalid"
                        > Your email is invalid. </small>
                        <small *ngIf="emailInput.errors.minlength" class="form-text text-danger"
                               appTranslate="global.messages.validate.email.minlength"
                        > Your email is required to be at least 5 characters. </small>
                        <small *ngIf="emailInput.errors.maxlength" class="form-text text-danger"
                               appTranslate="global.messages.validate.email.maxlength"
                        > Your email cannot be longer than 100 characters. </small>
                    </div>
                </div>
                <div class="form-group" *ngIf="languages && languages.length > 0">
                    <label for="langKey" appTranslate="settings.form.language">Language</label>
                    <select class="form-control" id="langKey" name="langKey" [(ngModel)]="account.langKey">
                        <option *ngFor="let language of languages" [value]="language"
                        >{{language | findLanguageFromKey}}</option>
                    </select>
                </div>
                <button type="submit" [disabled]="settingsForm.form.invalid || account.protectedUser"
                        class="btn btn-primary" appTranslate="settings.form.button">Save
                </button>
            </form>
        </div>
    </div>
</main>
