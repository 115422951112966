    <div class="d-flex mb-3" >
        <div class="mr-auto">
            <h2 appTranslate={{formSubArrayStrategy.labels.titleLabel}} >Items</h2>
        </div>
        <div >
            <button id="jh-create-entity"
                    type="button"
                    (click)="addItem()"
                    [disabled]="isItemEditing || !isCreateEnabled"
                    class="btn  btn-primary float-right btn-sm">
                <fa-icon [icon]="'plus'" ></fa-icon>
                <span appTranslate={{formSubArrayStrategy.labels.createLabel}}>Create</span>
            </button>
        </div>
    </div>

    <div class="d-flex" *ngIf="!(formArray.controls.length != 0)">
        <div appTranslate="global.no-data" class="mx-auto text-muted pt-3">
            No items to display
        </div>
    </div>
    <div class="table-responsive" *ngIf="formArray.controls.length != 0">
        <table class="table ">
            <thead>
                <tr>
                    <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let itemFormGroup of formArray.controls; let i=index;" >
                    <tr>

                        <ng-template [ngIf]="itemFormGroup.disabled" [ngIfElse]="edit" >
                            <ng-container [ngTemplateOutlet]="viewTemplate"
                                          [ngTemplateOutletContext]="{ $implicit: itemFormGroup, options: options}">
                            </ng-container>
                            <td class="text-right flex-btn-group-container">
                                <div *ngIf="isEditable">
                                    <button type="button"
                                            (click)="editItem(itemFormGroup)"
                                            [disabled]="isItemEditing"
                                            class="btn btn-default btn-sm mr-1">
                                        <fa-icon [icon]="'pencil-alt'" ></fa-icon>
                                        <span class="d-none d-md-inline" appTranslate="entity.action.edit">Edit</span>
                                    </button>
                                    <button
                                        type="button"
                                        (click)="deleteItemInternal(itemFormGroup)"
                                        [disabled]="isItemEditing"
                                        class="btn btn-danger btn-sm">
                                        <fa-icon [icon]="'trash-alt'" ></fa-icon>

                                    </button>
                                </div>
                            </td>
                        </ng-template>

                        <ng-template #edit>
                            <ng-container [ngTemplateOutlet]="editTemplate"
                                          [ngTemplateOutletContext]="{ $implicit: itemFormGroup, options: options}">
                            </ng-container>
                            <td class="text-right flex-btn-group-container">
                                <div>
                                    <button type="button"
                                            (click)="saveItem(itemFormGroup)"
                                            [disabled]="itemFormGroup.invalid || itemFormGroup.pristine"
                                            class="btn btn-primary btn-sm mr-1">
                                        <fa-icon [icon]="'save'" ></fa-icon>
                                        <span class="d-none d-md-inline" appTranslate="entity.action.save">Save</span>
                                    </button>
                                    <button
                                        type="button"
                                        (click)="cancelItemEdit(itemFormGroup)"
                                        class="btn btn-default btn-sm">
                                        <fa-icon [icon]="'ban'" ></fa-icon>
                                        <span class="d-none d-md-inline" appTranslate="entity.action.cancel">Cancel</span>
                                    </button>
                                </div>
                            </td>
                        </ng-template>

                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>

