import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {authnTokenKey} from "@core/service/auth/auth-jwt.service";
import {environment} from '@env';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {Observable} from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private localStorage: LocalStorageService,
        private sessionStorage: SessionStorageService,
        // private activatedRoute: ActivatedRoute,
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // const targetUrl = this.activatedRoute.snapshot.url;
        // if (targetUrl && targetUrl !== '/login') {
        //     this.localStorage.store('targetUrl', targetUrl);
        // }

        if (request?.url && !(/^http/.test(request.url)
            && !(environment.serverApiUrl && request.url.startsWith(environment.serverApiUrl)))
        ) {
            const token = this.localStorage.retrieve(authnTokenKey) || this.sessionStorage.retrieve(authnTokenKey);
            request = request.clone({
                setHeaders: {
                    'X-Use-Authorization': 'HEADER', ...(!!token ? {Authorization: 'Bearer ' + token,} : {})
                }
            });
        }
        return next.handle(request);
    }
}
