import {AfterContentInit, ContentChild, Directive, Host, HostListener, Input} from '@angular/core';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';
import {faSort, faSortDown, faSortUp, IconDefinition} from '@fortawesome/free-solid-svg-icons';

import {SortDirective} from './sort.directive';

@Directive({
    selector: '[appSortBy]'
})
export class SortByDirective implements AfterContentInit {
    @Input() appSortBy: string;
    @ContentChild(FaIconComponent, { static: true }) iconComponent: FaIconComponent;

    sortIcon: IconDefinition;
    sortAscIcon: IconDefinition;
    sortDescIcon: IconDefinition;

    constructor(@Host() private appSort: SortDirective) {
        this.appSort = appSort;
        this.sortIcon = faSort;
        this.sortAscIcon = faSortUp;
        this.sortDescIcon = faSortDown;
    }

    ngAfterContentInit(): void {
        if (this.appSort.predicate && this.appSort.predicate !== '_score' && this.appSort.predicate === this.appSortBy) {
            this.updateIconDefinition(this.iconComponent, this.appSort.ascending ? this.sortDescIcon : this.sortAscIcon);
            this.appSort.activeIconComponent = this.iconComponent;
        }
    }

    @HostListener('click')
    onClick() {
        if (this.appSort.predicate && this.appSort.predicate !== '_score') {
            this.appSort.sort(this.appSortBy);
            this.updateIconDefinition(this.appSort.activeIconComponent, this.sortIcon);
            this.updateIconDefinition(this.iconComponent, this.appSort.ascending ? this.sortDescIcon : this.sortAscIcon);
            this.appSort.activeIconComponent = this.iconComponent;
        }
    }

    private updateIconDefinition(iconComponent: FaIconComponent, icon: IconDefinition) {
        if (iconComponent) {
            iconComponent.icon = icon;
            iconComponent.ngOnChanges({});
        }
    }
}
