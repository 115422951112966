import {HttpClient, HttpResponse} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {environment} from "@env";
import {Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class AboutService {
    public resourceUrl = environment.serverApiUrl + 'management/info';

    constructor(private http: HttpClient) {}

    info(): Observable<HttpResponse<any>> {
        return this.http.get<Record<string, string>>(this.resourceUrl, {observe: 'response'});
    }
}
