import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '@env';

@Injectable({ providedIn: 'root' })
export class ActivateService {
    constructor(private http: HttpClient) {}

    get(key: string): Observable<any> {
        return this.http.get(environment.serverApiUrl + 'api/activate', {
            params: new HttpParams().set('key', key)
        });
    }
}
