export interface IProperty {
    id?: number;
    value?: string;
    propertyTypeName?: string;
    token?: string;
}

export class Property implements IProperty {
    constructor(public id?: number, public value?: string, public propertyTypeName?: string, public token?: string) {}
}
