import {Component, ContentChild, Input, TemplateRef} from '@angular/core';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {IFormSubArrayStrategy} from '@shared/component/form-sub-array/form-sub-array.strategy';

@Component({
    selector: 'app-form-array',
    templateUrl: './form-sub-array.component.html',
    styleUrls: ['./form-sub-array.scss']
})
export class JhiFormSubArrayComponent {
    @Input() formArray: FormArray;
    @Input() options: any;
    @Input() formSubArrayStrategy: IFormSubArrayStrategy;

    @Input() isCreateEnabled: boolean;
    @Input() isEditable = true;

    @ContentChild('header', { static: false }) headerTemplate: TemplateRef<any>;
    @ContentChild('view', { static: false }) viewTemplate: TemplateRef<any>;
    @ContentChild('edit', { static: false }) editTemplate: TemplateRef<any>;

    private itemToEdit = null;
    private isNew = false;

    constructor(private fb: FormBuilder) {}

    addItem() {
        const newItem = this.formSubArrayStrategy.createItem();
        this.itemToEdit = newItem;
        this.isNew = true;
        const newItemFormGroup = this.formSubArrayStrategy.createItemFormGroup
            ? this.formSubArrayStrategy.createItemFormGroup(newItem)
            : this.createItemFormGroupDefault(newItem);
        this.formArray.push(newItemFormGroup);
    }

    deleteItemInternal(formGroup: FormGroup) {
        if (formGroup.value.id) {
            let observable = this.formSubArrayStrategy.deleteItem(formGroup.value.id)
            if (observable) {
                observable.subscribe(() => this.deleteItemFromArray(formGroup));
                return;
            }
        }
        this.deleteItemFromArray(formGroup);
    }

    editItem(formGroup: FormGroup) {
        this.itemToEdit = formGroup.value;
        formGroup.enable();
    }

    saveItem(formGroup: FormGroup) {
        formGroup.disable();
        this.itemToEdit = null;
        this.isNew = false;
    }

    cancelItemEdit(formGroup: FormGroup) {
        if (this.isNew) {
            this.deleteItemInternal(formGroup);
            this.itemToEdit = null;
            this.isNew = false;
        } else {
            formGroup.reset(this.itemToEdit);
            this.saveItem(formGroup);
        }
    }

    private deleteItemFromArray(formGroup: FormGroup) {
        this.formArray.removeAt(this.formArray.controls.indexOf(formGroup));
    }

    private createItemFormGroupDefault(item: any) {
        return this.fb.group(item);
    }

    get isItemEditing() {
        return this.itemToEdit !== null;
    }
}
