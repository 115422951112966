import {Injectable} from '@angular/core';
import {SessionStorageService} from 'ngx-webstorage';

@Injectable({providedIn: 'root'})
export class StateStorageService {
    constructor(private $sessionStorage: SessionStorageService) {}

    getPreviousState() { return this.$sessionStorage.retrieve('previousState'); }

    resetPreviousState() { this.$sessionStorage.clear('previousState'); }

    storePreviousState(previousStateName, previousStateParams) {
        this.$sessionStorage.store('previousState', {name: previousStateName, params: previousStateParams});
    }

    getUrl() { return this.$sessionStorage.retrieve('previousUrl'); }

    resetUrl() { this.$sessionStorage.clear('previousUrl'); }

    storeUrl(url: string) { this.$sessionStorage.store('previousUrl', url); }

    getDestinationState() { return this.$sessionStorage.retrieve('destinationState'); }

    resetDestinationState() { this.$sessionStorage.clear('destinationState'); }

    storeDestinationState(destinationState, destinationStateParams, fromState) {
        this.$sessionStorage.store('destinationState', {
            destination: {
                name: destinationState.name,
                data: destinationState.data
            },
            params: destinationStateParams,
            from: {
                name: fromState.name
            }
        });
    }
}
