<div class="container-fluid">
    <div class="row vh-100 vw-100">
        <div class="col-4 mx-auto mt-5">
            <div class="card">
                <div class="card-header bg-white">
                    <h2 appTranslate="completePgEmailOtp.title"></h2>
                </div>
                <div class="card-body text-center">
                    <form role="form" (ngSubmit)="completePgEmailOtp()">
                        <div class="form-group">
                            <!--suppress HtmlFormInputWithoutLabel -->
                            <input type="text" class="form-control" name="otp" id="otp"
                                   placeholder="{{'completePgEmailOtp.form.otp' | translate}}"
                                   [(ngModel)]="otp">
                        </div>
                        <button
                            type="submit" class="btn btn-primary block full-width m-b"
                            appTranslate="completePgEmailOtp.form.button"
                        > Continue
                        </button>
                    </form>
                    <div class="mt-3">
                        <a (click)="resendPgEmailOtp()" href="javascript:void(0)" appTranslate="completePgEmailOtp.form.resend-otp">
                            Re-send the security code
                        </a>
                    </div>
                    <div class="mt-3">
                        <a [routerLink]="['/login']" appTranslate="completePgEmailOtp.form.back-to-login">
                            Back to Login screen
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
