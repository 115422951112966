<nav class="sidebar">
    <div class="sidebar-brand logo">
        <a routerLink="{{homeLink}}">
            <!--suppress HtmlRequiredAltAttribute, RequiredAttributes -->
            <img class="logo-img"/>
        </a>
    </div>
    <ul class="nav d-flex flex-column">
        <ng-container *ngFor="let item of items">
            <li class="nav-item" *appHasPrivilege="item.hasAnyAuthority">
                <a [routerLinkActiveOptions]="{ exact: true }" class="nav-link" routerLink="{{item.routerLink}}"
                   routerLinkActive="active">
                    <fa-icon [fixedWidth]="true" [icon]="item.icon" class="pr-2"></fa-icon>
                    <span appTranslate="{{item.title}}"></span>
                </a>
            </li>
        </ng-container>
    </ul>
    <div class="nav-item sidebar-toggle semi-fixed-bottom">
        <a class="nav-link" (click)="collapse()" [ngSwitch]="collapsed">
            <fa-icon *ngSwitchCase="false" [fixedWidth]="true" [icon]="'angle-double-left'" class="pr-2"></fa-icon>
            <fa-icon *ngSwitchCase="true" [fixedWidth]="true" [icon]="'angle-double-right'" class="pr-2"></fa-icon>
            <span appTranslate="collapse-sidebar">Collapse sidebar</span>
        </a>
    </div>
</nav>
