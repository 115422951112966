import {Injectable, Pipe, PipeTransform} from '@angular/core';

@Pipe({ name: 'excludeArray' })
@Injectable()
export class ExcludeArrayPipe implements PipeTransform {
    transform(items: any[], excludeArray: any[], predicate: (a) => any, skip: any): any[] {
        if (!items) {
            return [];
        }
        return items.filter(it => predicate(it) === skip || !excludeArray.some(excludeItem => excludeItem === predicate(it)));
    }
}
