export interface IPropertyExpression {
    id?: number;
    uuid?: string;
    expression?: string;
    propertyTypeName?: string;
    propertyTypeId?: number;
}

export class PropertyExpression implements IPropertyExpression {
    constructor(
        public id?: number,
        public uuid?: string,
        public expression?: string,
        public propertyTypeName?: string,
        public propertyTypeId?: number,
    ) {}
}
