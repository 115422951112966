import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

import {ActivateService} from './activate.service';

@Component({
    selector: 'app-activate',
    templateUrl: './activate.component.html'
})
export class ActivateComponent implements OnInit {
    error: string;
    success: string;

    constructor(private activateService: ActivateService, private route: ActivatedRoute) {}

    ngOnInit() {
        this.route.queryParams.subscribe(({ key }) => {
            this.activateService.get(key).subscribe(
                () => {
                    this.error = null;
                    this.success = 'OK';
                },
                () => {
                    this.success = null;
                    this.error = 'ERROR';
                }
            );
        });
    }
}
