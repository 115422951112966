export interface IPropertyChangeRequest {
    id?: number;
    value?: string;
    propertyTypeName?: string;
    token?: string;
    tokenType?: string;
    recordChangeRequestId?: number;
}

export class PropertyChangeRequest implements IPropertyChangeRequest {
    constructor(
        public id?: number,
        public value?: string,
        public propertyTypeName?: string,
        public token?: string,
        public tokenType?: string,
        public recordChangeRequestId?: number
    ) {}
}
