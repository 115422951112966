import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {LoginService} from '@core/service/login.service';
import {LocalStorageService} from "ngx-webstorage";
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

@Injectable()
export class AuthExpiredInterceptor implements HttpInterceptor {
    constructor(
        private activatedRoute: ActivatedRoute,
        private localStorage: LocalStorageService,
        private router: Router,
        private loginService: LoginService,
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(tap(() => {}, (err: any) => {
            if (err instanceof HttpErrorResponse && err.status === 401) {
                this.router.navigate(['login']).then();
                this.loginService.logout();
            }
        }));
    }
}
