export function numberOfBytes(base64String: string) {
    function endsWith(suffix: string, str: string) {
        return str.includes(suffix, str.length - suffix.length);
    }

    function paddingSize(value: string) {
        if (endsWith('==', value)) {
            return 2;
        }
        if (endsWith('=', value)) {
            return 1;
        }
        return 0;
    }
    return (base64String.length / 4) * 3 - paddingSize(base64String);
}
