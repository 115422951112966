import {IPropertyExpression} from '@core/model/api/property-expression.model';

export interface ICreateRecordAction {
    id?: number;
    dfgIdExpression?: string;
    externalIdExpression?: string;
    propertyExpressions?: IPropertyExpression[];
    recordTypeName?: string;
    recordTypeId?: number;
}

export class CreateRecordAction implements ICreateRecordAction {
    constructor(
        public id?: number,
        public dfgIdExpression?: string,
        public externalIdExpression?: string,
        public propertyExpressions?: IPropertyExpression[],
        public recordTypeName?: string,
        public recordTypeId?: number
    ) {
        this.propertyExpressions = propertyExpressions || [];
    }
}
