import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {JhiEventManager} from '@core/service/util/event-manager.service';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
    constructor(private eventManager: JhiEventManager) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(tap(() => {}, (err: any) => {
            if (err instanceof HttpErrorResponse)
                if (!(err.status === 401 && (err.message === '' || err.url && err.url.includes('/api/account'))))
                    this.eventManager.broadcast({name: 'monoApp.httpError', content: err});
        }));
    }
}
