import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AppAlertService} from '@core/service/util/app-alert.service';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

@Injectable()
export class NotificationInterceptor implements HttpInterceptor {
    constructor(private alertService: AppAlertService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
            if (!(event instanceof HttpResponse)) return;
            let alert = null;
            let alertParams = null;
            event.headers.keys().forEach(entry => {
                const lc = entry.toLowerCase();
                if (lc.endsWith('app-alert')) alert = event.headers.get(entry);
                else if (lc.endsWith('app-params')) alertParams = event.headers.get(entry);
                else if (entry.toLocaleLowerCase().endsWith('app-com.masterdata.dfg.security.custom-params')) {
                    alertParams = {};
                    event.headers.get(entry).split(',').forEach(element => {
                        const keyAndValue = element.split('=');
                        alertParams[`${keyAndValue[0].trim()}`] = keyAndValue[1].trim();
                    });
                }
            });
            if (alert && typeof alert === 'string')
                this.alertService.success(alert,
                    typeof alertParams === 'object' ? alertParams : {param: alertParams}, null);
        }, (ignored: any) => {}));
    }
}
