import {Component, Input} from '@angular/core';

/**
 * A component that will take care of item count statistics of a pagination.
 */
@Component({
    selector: 'app-item-count',
    template: `
        <div class="info app-item-count" appTranslate="global.item-count" [translateValues]="i18nValues()">
            /* [attr.translateValues] is used to get entire values in tests */
        </div>
        <ng-template #noI18n class="info app-item-count">
            Showing
            {{ (page - 1) * itemsPerPage == 0 ? 1 : (page - 1) * itemsPerPage + 1 }}
            - {{ page * itemsPerPage < total ? page * itemsPerPage : total }} of {{ total }} items.
        </ng-template>
    `
})
export class ItemCountComponent {
    /**
     *  current page number.
     */
    @Input() page: number;

    /**
     *  Total number of items.
     */
    @Input() total: number;

    /**
     *  Number of items per page.
     */
    @Input() itemsPerPage: number;

    constructor() {}

    /**
     * "translate-values" JSON of the template
     */
    i18nValues(): any {
        const first = (this.page - 1) * this.itemsPerPage === 0 ? 1 : (this.page - 1) * this.itemsPerPage + 1;
        const second = this.page * this.itemsPerPage < this.total ? this.page * this.itemsPerPage : this.total;

        return {
            first,
            second,
            total: this.total
        };
    }
}
