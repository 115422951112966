import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DashboardComponent} from './dashboard.component';
import {accountState} from '../account';

const routes: Routes = [
    {
        path: '',
        component: DashboardComponent,
        children: [
            {
                path: '',
                loadChildren: () => import('./dashboard-root/dashboard-root.module').then(m => m.DashboardRootModule)
            },
            {
                path: 'project-group/:projectGroupId',
                loadChildren: () =>
                    import('./dashboard-project-group/dashboard-project-group.module').then(m => m.DashboardProjectGroupModule)
            },
            {
                path: 'project-group/:projectGroupId/project/:projectId',
                loadChildren: () => import('./dashboard-project/dashboard-project.module').then(m => m.DashboardProjectModule)
            },
            ...accountState
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)]
})
export class DashboardRoutingModule {
}
