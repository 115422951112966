import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Credentials} from "@core/service/login.service";
import {environment} from '@env';
import {OK} from "http-status-codes";
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

export const authnTokenKey = 'authenticationToken';

type AuthnResult = { authnComplete: boolean };

@Injectable({providedIn: 'root'})
export class AuthServerProvider {
    constructor(
        private http: HttpClient,
        private $localStorage: LocalStorageService,
        private $sessionStorage: SessionStorageService,
    ) {}

    login(credentials: Credentials): Observable<AuthnResult> {
        return this.http.post<void>(environment.serverApiUrl + 'api/authenticate', credentials,
            {observe: 'response'}).pipe(map(rs => this.handleAuthnResponse(rs)));
    }

    reauthenticate(params?: HttpParams | { [param: string]: string | string[]; }): Observable<AuthnResult> {
        return this.http.post<void>(environment.serverApiUrl + 'api/reauthenticate', null,
            {observe: 'response', params: params}).pipe(map(rs => this.handleAuthnResponse(rs)));
    }

    resendPgEmailOtp(): Observable<boolean> {
        return this.http.post<void>(environment.serverApiUrl + 'api/resend-otp', null,
            {observe: 'response'}).pipe(map(rs => rs.ok));
    }

    completePgEmailOtp(otp: string): Observable<AuthnResult> { return this.reauthenticate({otp: otp}); }

    private handleAuthnResponse(rs: HttpResponse<any>): AuthnResult {
        const m = /^Bearer (.+)$/.exec(rs.headers.get('Authorization'));
        const jwt = m ? m[1] : null;
        if (!jwt) return {authnComplete: false};
        (rs.headers.get('X-Remember-Me') == '1' ? this.$localStorage : this.$sessionStorage).store(authnTokenKey, jwt);
        return {authnComplete: rs.status == OK};
    }

    logout(): Observable<any> {
        return new Observable(observer => {
            this.$localStorage.clear(authnTokenKey);
            this.$sessionStorage.clear(authnTokenKey);
            observer.complete();
        });
    }
}
