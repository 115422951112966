import {NgModule} from '@angular/core';
import {NgbDateAdapter} from '@ng-bootstrap/ng-bootstrap';
import {SharedLibsModule} from '@shared/shared-libs.module';
import {NgbDateMomentAdapter} from '@shared/util/datepicker-adapter';
import {COMPONENTS, DIRECTIVES, PIPES} from './components';

@NgModule({
    imports: [SharedLibsModule],
    declarations: [...PIPES, ...DIRECTIVES, ...COMPONENTS],
    exports: [SharedLibsModule, ...PIPES, ...DIRECTIVES, ...COMPONENTS],
    providers: [{provide: NgbDateAdapter, useClass: NgbDateMomentAdapter}],
})
export class SharedModule {}
