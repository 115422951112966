<div class="container-fluid">
    <div class="row vh-100">
        <div class="col-4 mx-auto mt-5">
            <div class="card">
                <div class="card-header bg-white">
                    <h2 appTranslate="login.title"></h2>
                </div>
                <div class="card-body  text-center">
                    <form role="form" (ngSubmit)="login()">
                        <div class="form-group">
                            <input type="text" class="form-control" name="username" id="username"
                                   placeholder="{{'global.form.username.placeholder' | translate}}"
                                   [(ngModel)]="username">
                        </div>
                        <div class="form-group">
                            <input type="password" class="form-control" name="password" id="password"
                                   placeholder="{{'login.form.password.placeholder' | translate}}"
                                   [(ngModel)]="password">
                        </div>
                        <div class="form-check float-left">
                            <label class="form-check-label" for="rememberMe">
                                <input class="form-check-input" type="checkbox" name="rememberMe" id="rememberMe"
                                       [(ngModel)]="rememberMe">
                                <span appTranslate="login.form.rememberme">Remember me</span>
                            </label>
                        </div>
                        <button type="submit" class="btn btn-primary block full-width m-b" appTranslate="login.form.button">Sign in</button>
                    </form>
                    <div class="mt-3">
                        <a [routerLink]="['/reset/request']" appTranslate="login.password.forgot">Did you forget your password?</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
