<div class="container-fluid">
    <div class="row vh-100">
        <div class="col-4 mx-auto mt-5">
            <div class="card">
                <div class="card-header bg-white">
                    <h2 appTranslate="reset.request.title">Reset your password</h2>
                </div>
                <div class="card-body  text-center">
                    <div class="alert alert-danger" appTranslate="reset.request.messages.notfound"
                         *ngIf="errorEmailNotExists">
                        <strong>Email address isn't registered!</strong> Please check and try again.
                    </div>

                    <div class="alert alert-warning" *ngIf="!success">
                        <span appTranslate="reset.request.messages.info">Enter the email address you used to register.</span>
                    </div>

                    <div class="alert alert-success" *ngIf="success === 'OK'">
                        <p appTranslate="reset.request.messages.success">Check your emails for details on how to reset
                            your password.</p>
                    </div>

                    <form *ngIf="!success" name="form" role="form" (ngSubmit)="requestReset()"
                          #resetRequestForm="ngForm">
                        <div class="form-group">
                            <input type="email" class="form-control" appAutofocus id="email" name="email"
                                   placeholder="{{'global.form.email.placeholder' | translate}}"
                                   [(ngModel)]="resetAccount.email" minlength=5 maxlength=254 #emailInput="ngModel"
                                   email required>
                            <div *ngIf="emailInput.dirty && emailInput.invalid">
                                <small class="form-text text-danger"
                                       *ngIf="emailInput.errors.required"
                                       appTranslate="global.messages.validate.email.required">
                                    Your email is required.
                                </small>
                                <small class="form-text text-danger"
                                       *ngIf="emailInput.errors.email"
                                       appTranslate="global.messages.validate.email.invalid">
                                    Your email is invalid.
                                </small>
                                <small class="form-text text-danger"
                                       *ngIf="emailInput.errors.minlength"
                                       appTranslate="global.messages.validate.email.minlength">
                                    Your email is required to be at least 5 characters.
                                </small>
                                <small class="form-text text-danger"
                                       *ngIf="emailInput.errors.maxlength"
                                       appTranslate="global.messages.validate.email.maxlength">
                                    Your email cannot be longer than 100 characters.
                                </small>
                            </div>
                        </div>
                        <button type="submit" [disabled]="resetRequestForm.form.invalid" class="btn btn-primary block full-width m-b"
                                appTranslate="reset.request.form.button">Reset
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
