<div class="container-fluid">
    <div class="row mt-5">
        <div class="col-md-4 mx-auto">


            <div [hidden]="!errorMessage">
                <h1 class="error-status" >Error!</h1>
                <div class="alert alert-danger">{{errorMessage}}
                </div>
            </div>
            <div [hidden]="!error403" class="text-center">
                <h1 class="error-status" >403</h1>
                <span>
                    {{ 'error.http.403' | translate}}
                </span>

            </div>
            <div [hidden]="!error404"  appTranslate="error.http.404" class=" alert text-center">
                <h1 class="error-status">404</h1>
                <span>
                    {{ 'error.http.404' | translate}}
                </span>

            </div>
        </div>
    </div>
</div>
