import {Directive, ElementRef, Input, OnChanges, OnInit, Optional} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Directive({
    selector: '[appTranslate]'
})
export class TranslateDirective implements OnChanges, OnInit {
    @Input() appTranslate: string;
    @Input() translateValues: any;

    constructor(private el: ElementRef, @Optional() private translateService: TranslateService) {}

    ngOnInit() {
        this.translateService.onLangChange.subscribe(() => {
            this.getTranslation();
        });
    }

    ngOnChanges() {
        this.getTranslation();
    }

    private getTranslation() {
        this.translateService.get(this.appTranslate, this.translateValues).subscribe(
            value => {
                this.el.nativeElement.innerHTML = value;
            },
            () => {
                return `translation-not-found[${this.appTranslate}]`;
            }
        );
    }
}
