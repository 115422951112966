import {HttpClient, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Account, checkYourPrivilege, convertAccount, isSubjectToPartialApplication} from "@core/model/api";
import {Holder} from "@core/model/api/holder";
import {AuthServerProvider} from "@core/service/auth/auth-jwt.service";
import {InactivityService, refreshRate} from "@core/service/auth/inactivity.service";
import {environment} from '@env';
import {SessionStorageService} from 'ngx-webstorage';
import {interval, Observable, of, Subject} from 'rxjs';
import {takeUntil} from "rxjs/operators";

@Injectable({providedIn: 'root'})
export class AccountService {
    private userIdentity: Account;
    private authenticationState = new Subject<Account>();
    private logout = new Subject<void>();

    constructor(
        private sessionStorage: SessionStorageService,
        private http: HttpClient,
        private inactivityService: InactivityService,
        private authServerProvider: AuthServerProvider,
    ) { }

    fetch(): Observable<HttpResponse<Account>> {
        return this.http.get<Account>(`${environment.serverApiUrl}api/account`, {observe: 'response'});
    }

    save(account: any): Observable<HttpResponse<Account>> {
        return this.http.post<Account>(`${environment.serverApiUrl}api/account`, account, {observe: 'response'});
    }

    authenticate(identity) {
        this.userIdentity = identity;
        this.authenticationState.next(this.userIdentity);
    }

    identity(force?: boolean): Promise<Account> {
        // check and see if we have retrieved the userIdentity data from the server.
        // if we have, reuse it by immediately resolving
        if (!force && this.userIdentity) return Promise.resolve(this.userIdentity);

        // retrieve the userIdentity data from the server, update the identity object, and then resolve.
        return this.fetch().toPromise().then(rs => {
            const account = rs.body;
            if (account) {
                if (!this.userIdentity)
                    interval(refreshRate).pipe(takeUntil(this.logout)).subscribe(_ => {
                        (this.inactivityService.isActive() ? this.authServerProvider.reauthenticate() : of(null))
                            .subscribe(() => this.identity(true).then());
                    });
                this.userIdentity = convertAccount(account);
            } else {
                this.userIdentity = null;
                this.logout.next();
            }
            this.authenticationState.next(this.userIdentity);
            return this.userIdentity;
        }).catch(_ => {
            this.userIdentity = null;
            this.authenticationState.next(this.userIdentity);
            this.logout.next();
            return null;
        });
    }

    isAuthenticated() { return !!this.userIdentity; }

    getAuthenticationState() { return this.authenticationState.asObservable(); }

    getImageUrl() { return this.userIdentity?.imageUrl; }

    getHolderAuthzData(
        holderId: number, account?: Account | Promise<Account>, identityRefresher?: () => Promise<Account>
    ) {
        if (!identityRefresher) identityRefresher = () => Promise.resolve(null);
        return (account instanceof Promise ? account : Promise.resolve(account ?? this.userIdentity))
            .then(a => a?.holderDataLookup[holderId] ?? identityRefresher().then(a => a?.holderDataLookup[holderId]));
    }

    checkYourPrivilege(authzData: Holder, privilege: string | string[]) {
        if (Array.isArray(privilege))
            return privilege.every(p => this.checkYourPrivilege(authzData, p));
        return privilege == 'unspecified:unspecified'
            || checkYourPrivilege(authzData?.effectivePrivileges, privilege)
            || isSubjectToPartialApplication(privilege) && checkYourPrivilege(authzData?.partialPrivileges, privilege);
    }
}
