import {Injectable} from "@angular/core";

export const refreshRate = 189737; // √10 minutes

@Injectable({providedIn: 'root'})
export class InactivityService {
    private inactive: boolean;
    private inactivityTimeout;

    constructor() { this.armTimeouts(); }

    private armTimeouts() {
        this.inactivityTimeout = setTimeout(() => {
            console.info("user became inactive");
            this.inactive = true;
        }, refreshRate / 2);
    }

    public isActive() { return !this.inactive; }

    public setActive() {
        if (this.inactive)
            console.info('user became active');
        this.inactive = false;
        clearTimeout(this.inactivityTimeout);
        this.armTimeouts();
    }
}
