export enum HolderType {
    Global = 'Global',
    ProjectGroup = 'ProjectGroup',
    Project = 'Project',
    Stage = 'Stage',
}

export enum AuthzFlowMode {
    RECOMMENDED = 'RECOMMENDED',
    SALESFORCE = 'SALESFORCE',
}

export class Authority {
    constructor(
        public uuid: string,
        public roleTemplate: string,
        public holder: Holder,
    ) {}
}

export class Holder {
    constructor(
        public id: number,
        public holderType: HolderType,
        public parent: Holder | undefined,
        public uuid: string,
        public name: string,
        public authorities: Authority[] | null,
        public selfServicePasswordRecovery: boolean,
        public pgEmailOtpEnabled: boolean,
        public authzFlowMode: AuthzFlowMode,
        public effectivePrivileges: Set<string>,
        public partialPrivileges: Set<string>,
    ) {}

    isClass1Holder(): boolean { return this.holderType != HolderType.Stage; }

    canHaveUsers(): boolean { return this.isClass1Holder(); }

    allAncestors(): Holder[] {
        let rv = [];
        for (let h = this.parent; h && h.parent !== h; h = h.parent)
            rv.push(h);
        return rv;
    }

    getLongName(): string { return [...this.allAncestors().reverse(), this].map(h => h.name).join(' / '); }
}
