import {Component, HostListener, OnInit} from '@angular/core';
import {ActivatedRouteSnapshot, NavigationEnd, NavigationError, Router} from '@angular/router';
import {InactivityService} from "@core/service/auth/inactivity.service";
import {JhiLanguageHelper} from '@core/service/language/language.helper';
import {JhiLanguageService} from "@core/service/language/language.service";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
    constructor(
        private jhiLanguageHelper: JhiLanguageHelper,
        private languageService: JhiLanguageService,
        private router: Router,
        private inactivityService: InactivityService,
    ) {}

    private getPageTitle(routeSnapshot: ActivatedRouteSnapshot) {
        if (!routeSnapshot) return null;
        return this.getPageTitle(routeSnapshot.firstChild) ?? routeSnapshot.data?.pageTitle ?? 'monoApp';
    }

    ngOnInit() {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd)
                this.jhiLanguageHelper.updateTitle(this.getPageTitle(this.router.routerState.snapshot.root));
            if (event instanceof NavigationError && event.error.status === 404)
                this.router.navigate(['/404']).then();
        });
    }

    @HostListener('window:mouseup')
    @HostListener('window:keyup')
    resetTimeouts() { this.inactivityService.setActive() }
}
