import {CreateRecordAction, ICreateRecordAction} from '@core/model/api/create-record-action.model';
import {SearchAction} from '@core/model/api/search-action.model';

export const enum ActionType {
    CREATE = 'CREATE',
    SEARCH = 'SEARCH',
}

export interface IFilter {
    id?: number;
    name?: string;
    actionType?: ActionType;
    expression?: string;
    createRecordAction?: ICreateRecordAction;
    searchAction?: SearchAction;
}

export class Filter implements IFilter {
    constructor(
        public id?: number,
        public name?: string,
        public actionType?: ActionType,
        public expression?: string,
        public createRecordAction = new CreateRecordAction(),
        public searchAction = new SearchAction(),
    ) {}
}
