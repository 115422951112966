import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot, UrlSegment} from '@angular/router';
import {Observable, of} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';

import * as _ from 'lodash';
import {IBreadcrumb} from './breadcrumb';

@Injectable({ providedIn: 'root' })
export class BreadcrumbsResolver implements Resolve<IBreadcrumb[]> {
    constructor(private translateService: TranslateService) {}

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IBreadcrumb[]> {
        const data = route.routeConfig.data;

        const path = this.getFullPath(route);

        let text = typeof data.breadcrumbs === 'string' ? data.breadcrumbs : data.breadcrumbs.text || data.text || path;
        let icon = data.breadcrumbs ? data.breadcrumbs.icon : null;
        text = this.stringFormat(text, route.data);

        const crumbs: IBreadcrumb[] = [{ text, path, icon }];

        return of(crumbs);
    }

    private getFullPath(route: ActivatedRouteSnapshot): string {
        const relativePath = (segments: UrlSegment[]) => segments.reduce((a, v) => (a += '/' + v.path), '');
        const fullPath = (routes: ActivatedRouteSnapshot[]) => routes.reduce((a, v) => (a += relativePath(v.url)), '');
        return fullPath(route.pathFromRoot);
    }
    private stringFormat(template: string, binding: any): string {
        return !this.translateService.instant(template).includes('translation-not-found')
            ? _.template(this.translateService.instant(template))(binding)
            : _.template(template)(binding);
    }
}
