import {IProject} from '@core/model/api/project.model';

export interface IProjectGroup {
    id?: number;
    name?: string;
    status?: string;
    projects?: IProject[];
}

export class ProjectGroup implements IProjectGroup {
    constructor(public id?: number, public name?: string, status?: string, public projects?: IProject[]) {}
}
