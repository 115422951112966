import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {accountPublicState} from './account';
import {errorRoute} from '@core/component/error/error.route';

const routes: Routes = [...accountPublicState, ...errorRoute];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
