import {IProperty} from '@core/model/api/property.model';

export interface IRecord {
    id?: number;
    externalId?: string;
    recordTypeName?: string;
    stageId?: number;
    stageName?: string;
    createdDate?: string;
    lastModifiedDate?: string;
    properties?: IProperty[];
}

export class Record implements IRecord {
    constructor(
        public id?: number,
        public externalId?: string,
        public recordTypeName?: string,
        public stageId?: number,
        public stageName?: string,
        public properties: IProperty[] = []
    ) {}
}
