<ol class="breadcrumb bg-white">
    <li class="breadcrumb-item">
        <a [routerLink]="['/']">
            <fa-icon [icon]="'home'" ></fa-icon>
        </a>
    </li>
    <li class="breadcrumb-item" *ngFor="let breadcrumb of breadcrumbs ; let last = last;">
        <a *ngIf="!last" [routerLink]="[breadcrumb.path]">
            <fa-icon *ngIf="breadcrumb.icon" [icon]="breadcrumb.icon" ></fa-icon>
            <span>{{breadcrumb.text}}</span>
        </a>
        <strong *ngIf="last">
            <fa-icon *ngIf="breadcrumb.icon" [icon]="breadcrumb.icon" ></fa-icon>{{breadcrumb.text}}
        </strong>
    </li>
</ol>
