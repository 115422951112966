import {Directive, forwardRef, Input} from '@angular/core';
import {FormControl, NG_VALIDATORS} from '@angular/forms';

@Directive({
    selector: '[appMin][ngModel]',
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    providers: [{ provide: NG_VALIDATORS, useExisting: forwardRef(() => MinValidatorDirective), multi: true }]
})
export class MinValidatorDirective {
    @Input() appMin: number;

    constructor() {}

    validate(c: FormControl) {
        return c.value === undefined || c.value === null || c.value >= this.appMin
            ? null
            : {
                  min: {
                      valid: false
                  }
              };
    }
}
