export enum LockoutMode {
    CONVENTIONAL = 'CONVENTIONAL',
    SMART = 'SMART',
}

export class Branding {
    constructor(
        public sendFrom?: string | null,
        public baseUrl?: string | null,
        public lockoutThreshold?: number | null,
        public lockoutMode?: LockoutMode | null,
        public lockoutDuration?: string | null,
        public lockoutCounterResetInterval?: string | null,
        public tokenValidityDuration?: string | null,
        public rememberMeTokenValidityDuration?: string | null,
    ) {}
}
