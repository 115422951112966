import {Component, OnInit} from '@angular/core';
import {Account} from "@core/model/api";
import {AccountService} from '@core/service/auth/account.service';
import {JhiLanguageHelper} from '@core/service/language/language.helper';
import {JhiLanguageService} from '@core/service/language/language.service';

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html'
})
export class SettingsComponent implements OnInit {
    error: string;
    success: string;

    account: Account;
    languages: string[];

    constructor(
        private accountService: AccountService,
        private languageService: JhiLanguageService,
        private languageHelper: JhiLanguageHelper
    ) {}

    ngOnInit() {
        this.accountService.identity().then(a => this.account = Object.assign(new Account(), a));
        this.languageHelper.getAll().then(languages => this.languages = languages);
    }

    save() {
        this.accountService.save(this.account.subsetForUpdate()).subscribe(
            () => {
                this.error = null;
                this.success = 'OK';
                this.accountService.identity(true).then(a => this.account = Object.assign(new Account(), a));
                this.languageService.getCurrent().then(current => {
                    if (this.account.langKey != current)
                        this.languageService.changeLanguage(this.account.langKey);
                });
            },
            () => {
                this.error = 'ERROR';
                this.success = null;
            }
        );
    }
}
