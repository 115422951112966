import {Directive, Injectable, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {AccountService} from '@core/service/auth/account.service';
import {ActivatedRoute, ParamMap} from "@angular/router";
import {Observable} from "rxjs";
import {UserRouteAccessService} from "@core/service/auth/user-route-access-service";

@Injectable()
export class HasPrivilegeService {
    constructor(private route: ActivatedRoute) {
    }

    getParams(): Observable<ParamMap> {
        return this.route.paramMap;
    }
}

@Directive({
    selector: '[appHasPrivilege]',
    providers: [HasPrivilegeService],
})
export class HasPrivilegeDirective {
    private privileges: string[];

    constructor(
        private accountService: AccountService,
        private templateRef: TemplateRef<any>,
        private viewContainerRef: ViewContainerRef,
        private hasAnyAuthorityService: HasPrivilegeService,
        private userRouteAccessService: UserRouteAccessService,
    ) {
    }

    @Input()
    set appHasPrivilege(value: string | string[]) {
        this.privileges = typeof value === 'string' ? [value] : value;
        this.updateView();
        // Get notified each time authentication state changes.
        this.accountService.getAuthenticationState().subscribe(_ => this.updateView());
    }

    private updateView(): void {
        this.viewContainerRef.clear();
        this.hasAnyAuthorityService.getParams().subscribe({
            next: paramMap => {
                this.userRouteAccessService.getHolderAuthzData(paramMap).then((authzData) => {
                    if (this.accountService.checkYourPrivilege(authzData, this.privileges))
                        this.viewContainerRef.createEmbeddedView(this.templateRef);
                });
            },
        });
    }
}
