import {HttpErrorResponse} from "@angular/common/http";
import {Component, OnDestroy} from '@angular/core';
import {AppAlertService} from '@core/service/util/app-alert.service';
import {JhiEventManager} from '@core/service/util/event-manager.service';
import {TranslateService} from '@ngx-translate/core';
import {RELATED_ENTITIES_DELETION_PROBLEM} from '@shared/constants/error.constants';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-alert-error',
    templateUrl: './alert.component.html',
})
export class AppAlertErrorComponent implements OnDestroy {
    alerts: any[];
    subscription: Subscription;

    constructor(
        private alertService: AppAlertService,
        private eventManager: JhiEventManager,
        private translateService: TranslateService,
    ) {
        this.alerts = [];
        this.subscription = eventManager.subscribe('monoApp.httpError', ({content}: { content: HttpErrorResponse }) => {
            const rs = content;
            // noinspection FallThroughInSwitchStatementJS
            switch (rs.status) {
                case 0:
                    this.addErrorAlert('Server not reachable', 'error.server.not.reachable');
                    break;
                case 400:
                    let errorHeader = null, entityKey = null;
                    rs.headers.keys().forEach(entry => {
                        const lc = entry.toLowerCase();
                        if (lc.endsWith('app-error')) errorHeader = rs.headers.get(entry);
                        else if (lc.endsWith('app-params')) entityKey = rs.headers.get(entry);
                    });
                    if (errorHeader)
                        this.addErrorAlert(errorHeader, errorHeader,
                            {entityName: translateService.instant(`global.menu.entities.${entityKey}`)});
                    else if (rs.error.fieldErrors) {
                        for (let fieldError of rs.error.fieldErrors) {
                            if (['Min', 'Max', 'DecimalMin', 'DecimalMax'].includes(fieldError.message))
                                fieldError.message = 'Size';
                            // convert 'something[14].other[4].id' to 'something[].other[].id' so translations can be written to it
                            const fieldName = translateService.instant(
                                `monoApp.${fieldError.objectName}.${(fieldError.field.replace(/\[\d*]/g, '[]'))}`);
                            this.addErrorAlert(`Error on field "${fieldName}"`, `error.${fieldError.message}`, {fieldName});
                        }
                    } else if (rs.error.message)
                        this.addErrorAlert(rs.error.message, rs.error.message, rs.error.params);
                    else this.addErrorAlert(rs.error);
                    break;
                case 404:
                    this.addErrorAlert('Not found', 'error.url.not.found');
                    break;
                case 500:
                    if (rs.error.type == RELATED_ENTITIES_DELETION_PROBLEM) {
                        let iseHeader = null, entityKey = null;
                        rs.headers.keys().forEach(entry => {
                            const lc = entry.toLowerCase();
                            if (lc.endsWith('app-error')) iseHeader = rs.headers.get(entry);
                            else if (lc.endsWith('app-params')) entityKey = rs.headers.get(entry);
                        });
                        if (!(iseHeader && entityKey)) this.addErrorAlert(rs.error.title);
                        else this.addErrorAlert(iseHeader, iseHeader,
                            {entityName: translateService.instant(`global.menu.entities.${entityKey}`)});
                        break;
                    }
                default:
                    this.addErrorAlert(rs.error.message ?? rs.error, rs.error.message, rs.error.params);
            }
        });
    }

    setClasses(alert) { return {toast: !!alert.toast, [alert.position]: true,}; }

    ngOnDestroy() {
        if (!this.subscription) {
            this.eventManager.destroy(this.subscription);
            this.alerts = [];
        }
    }

    addErrorAlert(message, key?, data?) {
        this.alerts.push(this.alertService.addAlert({
            type: 'danger',
            msg: key ?? message,
            params: data,
            timeout: 0,
            toast: this.alertService.isToast(),
            scoped: true,
        }, this.alerts));
    }
}
