import {Component, OnInit} from '@angular/core';
import {PasswordResetInitService} from './password-reset-init.service';
import {EMAIL_NOT_FOUND_TYPE} from '@shared/constants/error.constants';

@Component({
    selector: 'app-password-reset-init',
    templateUrl: './password-reset-init.component.html'
})
export class PasswordResetInitComponent implements OnInit {
    error: string;
    errorEmailNotExists: string;
    resetAccount: any;
    success: string;

    constructor(private passwordResetInitService: PasswordResetInitService) {}

    ngOnInit() {
        this.resetAccount = {};
    }

    requestReset() {
        this.error = null;
        this.errorEmailNotExists = null;

        this.passwordResetInitService.save(this.resetAccount.email).subscribe(
            () => {
                this.success = 'OK';
            },
            response => {
                this.success = null;
                if (response.status === 400 && response.error.type === EMAIL_NOT_FOUND_TYPE) {
                    this.errorEmailNotExists = 'ERROR';
                } else {
                    this.error = 'ERROR';
                }
            }
        );
    }
}
