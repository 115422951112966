import {Route} from '@angular/router';

import {PasswordComponent} from './password.component';
import {UserRouteAccessService} from '@core/service/auth/user-route-access-service';

export const passwordRoute: Route = {
    path: 'password',
    component: PasswordComponent,
    data: {
        privileges: ['unspecified:unspecified'],
        pageTitle: 'global.menu.account.password'
    },
    canActivate: [UserRouteAccessService]
};
