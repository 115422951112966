import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Account} from "@core/model/api";
import {AccountService} from '@core/service/auth/account.service';
import {StateStorageService} from "@core/service/auth/state-storage.service";
import {JhiLanguageHelper} from '@core/service/language/language.helper';

import {JhiLanguageService} from '@core/service/language/language.service';
import {LoginService} from '@core/service/login.service';
import {AboutService} from "@core/service/util";
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {SessionStorageService} from 'ngx-webstorage';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
    isNavbarCollapsed: boolean;
    languages: any[];
    version: string;
    @Input() currentAccount: Account;
    currentLanguage: any;

    constructor(
        private loginService: LoginService,
        private languageService: JhiLanguageService,
        private languageHelper: JhiLanguageHelper,
        private sessionStorage: SessionStorageService,
        private accountService: AccountService,
        private router: Router,
        private translateService: TranslateService,
        private aboutService: AboutService,
        private stateStorageService: StateStorageService,
    ) {
        this.version = 'NO VERSION';
        this.isNavbarCollapsed = true;
    }

    ngOnInit() {
        this.languageHelper.getAll().then(languages => this.languages = languages);
        this.accountService.identity().then(account => this.currentAccount = account);
        this.languageService.getCurrent().then(currentLanguage => this.currentLanguage = currentLanguage);
        this.translateService.onLangChange.subscribe((event: LangChangeEvent) => this.currentLanguage = event.lang);
        this.aboutService.info().subscribe(rs => this.version = `${rs.body?.git?.build?.version ?? ''} ` +
            `${/true/i.test(rs.body?.git?.dirty) && '~' || ''}${rs.body?.git?.commit?.id?.abbrev ?? ''}`);
    }

    changeLanguage(languageKey: string) {
        this.sessionStorage.store('locale', languageKey);
        this.languageService.changeLanguage(languageKey);
    }

    collapseNavbar() { this.isNavbarCollapsed = true; }

    isAuthenticated() { return this.accountService.isAuthenticated(); }

    login() { this.router.navigate([this.router.url === '/register' ? './login' : '/login']).then(); }

    logout() {
        this.collapseNavbar();
        this.loginService.logout();
        this.stateStorageService.resetUrl();
        this.router.navigate(['login']);
    }

    toggleNavbar() {
        this.isNavbarCollapsed = !this.isNavbarCollapsed;
    }

    getImageUrl() { return this.isAuthenticated() ? this.accountService.getImageUrl() : null; }
}
