<div class="container-fluid">
    <div class="row vh-100">
        <div class="col-4 mx-auto mt-5">
            <div class="card">
                <div class="card-header bg-white">
                    <h2 appTranslate="reset.finish.title">Reset password</h2>
                </div>
                <div class="card-body  ">

                    <div class="alert alert-danger" appTranslate="reset.finish.messages.keymissing" *ngIf="keyMissing">
                        <strong>The password reset key is missing.</strong>
                    </div>

                    <div class="alert alert-warning" *ngIf="!success && !keyMissing">
                        <span appTranslate="reset.finish.messages.info">Choose a new password</span>
                    </div>

                    <div class="alert alert-danger" *ngIf="error">
                        <p appTranslate="reset.finish.messages.error">Your password couldn't be reset. Remember a
                            password request is only valid for 24 hours.</p>
                    </div>

                    <p class="alert alert-success" *ngIf="success">
                        <span
                            appTranslate="reset.finish.messages.success"><strong>Your password has been reset.</strong> Please </span>
                        <a class="alert-link" (click)="login()" appTranslate="global.messages.info.authenticated.link">sign
                            in</a>.
                    </p>

                    <div class="alert alert-danger" *ngIf="doNotMatch" appTranslate="global.messages.error.dontmatch">
                        The password and its confirmation do not match!
                    </div>

                    <div *ngIf="!keyMissing">
                        <form *ngIf="!success" name="form" role="form" (ngSubmit)="finishReset()"
                              #passwordForm="ngForm">
                            <div class="form-group">
                                <input type="password" class="form-control" id="password" name="password"
                                       #passwordInput="ngModel"
                                       placeholder="{{'global.form.newpassword.placeholder' | translate}}"
                                       [(ngModel)]="resetAccount.password" minlength=4 maxlength=50 required>
                                <div *ngIf="passwordInput.dirty && passwordInput.invalid">
                                    <small class="form-text text-danger"
                                           *ngIf="passwordInput.errors.required"
                                           appTranslate="global.messages.validate.newpassword.required">
                                        Your password is required.
                                    </small>
                                    <small class="form-text text-danger"
                                           *ngIf="passwordInput.errors.minlength"
                                           appTranslate="global.messages.validate.newpassword.minlength">
                                        Your password is required to be at least 4 characters.
                                    </small>
                                    <small class="form-text text-danger"
                                           *ngIf="passwordInput.errors.maxlength"
                                           appTranslate="global.messages.validate.newpassword.maxlength">
                                        Your password cannot be longer than 50 characters.
                                    </small>
                                </div>
                                <app-password-strength-bar
                                    [passwordToCheck]="resetAccount.password"></app-password-strength-bar>
                            </div>

                            <div class="form-group">
                                <input type="password" class="form-control" id="confirmPassword" name="confirmPassword"
                                       #confirmPasswordInput="ngModel"
                                       placeholder="{{'global.form.confirmpassword.placeholder' | translate}}"
                                       [(ngModel)]="confirmPassword" minlength=4 maxlength=50 required>
                                <div *ngIf="confirmPasswordInput.dirty && confirmPasswordInput.invalid">
                                    <small class="form-text text-danger"
                                           *ngIf="confirmPasswordInput.errors.required"
                                           appTranslate="global.messages.validate.confirmpassword.required">
                                        Your password confirmation is required.
                                    </small>
                                    <small class="form-text text-danger"
                                           *ngIf="confirmPasswordInput.errors.minlength"
                                           appTranslate="global.messages.validate.confirmpassword.minlength">
                                        Your password confirmation is required to be at least 4 characters.
                                    </small>
                                    <small class="form-text text-danger"
                                           *ngIf="confirmPasswordInput.errors.maxlength"
                                           appTranslate="global.messages.validate.confirmpassword.maxlength">
                                        Your password confirmation cannot be longer than 50 characters.
                                    </small>
                                </div>
                            </div>
                            <button type="submit" [disabled]="passwordForm.form.invalid" class="btn btn-primary block full-width m-b"
                                    appTranslate="reset.finish.form.button">Reset Password
                            </button>
                        </form>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
