import {IPropertyChangeRequest} from '@core/model/api/property-change-request.model';

export enum ChangeRequestStatus {
    WAIT = 'WAIT',
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED'
}

export interface IRecordChangeRequest {
    id?: number;
    externalId?: string;
    status?: ChangeRequestStatus;
    recordTypeName?: string;
    properties?: IPropertyChangeRequest[];
    stageName?: string;
    stageId?: number;
    createdDate?: Date;
    lastModifiedDate?: Date;
}

export class RecordChangeRequest implements IRecordChangeRequest {
    constructor(
        public id?: number,
        public externalId?: string,
        public status: ChangeRequestStatus = ChangeRequestStatus.WAIT,
        public recordTypeName?: string,
        public properties: IPropertyChangeRequest[] = [],
        public stageName?: string,
        public stageId?: number,
        public createdDate?: Date,
        public lastModifiedDate?: Date
    ) {}
}
