import {Directive, forwardRef, Input} from '@angular/core';
import {FormControl, NG_VALIDATORS} from '@angular/forms';

@Directive({
    selector: '[appMax][ngModel]',
    providers: [{ provide: NG_VALIDATORS, useExisting: forwardRef(() => MaxValidatorDirective), multi: true }]
})
export class MaxValidatorDirective {
    @Input() appMax: number;

    constructor() {}

    validate(c: FormControl) {
        return c.value === undefined || c.value === null || c.value <= this.appMax
            ? null
            : {
                  max: {
                      valid: false
                  }
              };
    }
}
