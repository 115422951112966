import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {BreadcrumbsService} from './breadcrumbs.service';
import {IBreadcrumb} from '@shared/component/breadcrumb/breadcrumb';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss']
})
export class JhiBreadcrumbComponent implements OnInit, OnDestroy {
    public breadcrumbs: IBreadcrumb[];
    private breadcrumbsSubscription: Subscription;

    constructor(public breadcrumbsService: BreadcrumbsService) {}

    ngOnInit() {
        this.breadcrumbsSubscription = this.breadcrumbsService.breadcrumbs.subscribe(breadcrumbs => {
            this.breadcrumbs = breadcrumbs;
        });
    }

    ngOnDestroy(): void {
        if (this.breadcrumbsSubscription) {
            this.breadcrumbsSubscription.unsubscribe();
        }
    }
}
