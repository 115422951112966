export interface IUser {
    uuid?: string;
    login?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    activated?: boolean;
    langKey?: string;
    createdBy?: string;
    createdDate?: Date;
    lastModifiedBy?: string;
    lastModifiedDate?: Date;
    password?: string;
    holderId?: number;
    authorities?: string[];
    protectedObject?: boolean;
    dontSendWelcomeEmail?: boolean;
    exemptFromPgEmailOtp?: boolean;
    otpEmail?: string;
}

export class User implements IUser {
    constructor(
        public uuid?: string,
        public login?: string,
        public firstName?: string,
        public lastName?: string,
        public email?: string,
        public activated?: boolean,
        public langKey?: string,
        public createdBy?: string,
        public createdDate?: Date,
        public lastModifiedBy?: string,
        public lastModifiedDate?: Date,
        public password?: string,
        public holderId?: number,
        public authorities?: string[],
        public protectedObject?: boolean,
        public dontSendWelcomeEmail?: boolean,
        public exemptFromPgEmailOtp?: boolean,
        public otpEmail?: string,
    ) {
        this.uuid = uuid ?? null;
        this.login = login ?? null;
        this.firstName = firstName ?? null;
        this.lastName = lastName ?? null;
        this.email = email ?? null;
        this.activated = activated ?? false;
        this.langKey = langKey ?? null;
        this.createdBy = createdBy ?? null;
        this.createdDate = createdDate ?? null;
        this.lastModifiedBy = lastModifiedBy ?? null;
        this.lastModifiedDate = lastModifiedDate ?? null;
        this.password = password ?? null;
        this.holderId = holderId ?? null;
        this.authorities = authorities ?? [];
        this.protectedObject = protectedObject ?? false;
        this.dontSendWelcomeEmail = dontSendWelcomeEmail ?? false;
        this.exemptFromPgEmailOtp = exemptFromPgEmailOtp ?? false;
        this.otpEmail = otpEmail ?? null;
    }
}
