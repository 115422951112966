import {Route} from '@angular/router';

import {SettingsComponent} from './settings.component';
import {UserRouteAccessService} from '@core/service/auth/user-route-access-service';

export const settingsRoute: Route = {
    path: 'settings',
    component: SettingsComponent,
    data: {
        privileges: ['unspecified:unspecified'],
        pageTitle: 'global.menu.account.settings'
    },
    canActivate: [UserRouteAccessService]
};
