export const enum StageType {
    DEVELOPMENT = 'DEVELOPMENT',
    TEST = 'TEST',
    PRODUCTION = 'PRODUCTION',
}

export interface IStage {
    stageType?: StageType;
    id?: number;
    name?: string;
    externalId?: string;
    projectId?: number;
    activeConfigurationVersionId?: number,
    assignedConfigurationVersions?: { id: number, name: string }[],
}

export class Stage implements IStage {
    constructor(
        public stageType?: StageType,
        public id?: number,
        public name?: string,
        public externalId?: string,
        public projectId?: number,
        public activeConfigurationVersionId?: number,
        public assignedConfigurationVersions?: { id: number, name: string }[],
    ) {}
}
