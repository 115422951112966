import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {StateStorageService} from "@core/service/auth/state-storage.service";
import {Credentials, LoginService} from '@core/service/login.service';
import {JhiEventManager} from '@core/service/util/event-manager.service';

export class LoginComponentBase {
    constructor(
        private eventManager: JhiEventManager,
        private stateStorageService: StateStorageService,
        private router: Router,
    ) {}

    protected handleSuccess(loginResult) {
        if (loginResult.authnComplete) {
            this.eventManager.broadcast({
                name: 'authenticationSuccess',
                content: 'Sending Authentication Success'
            });
            let url = this.stateStorageService.getUrl();
            this.router.navigate(url ? [url] : loginResult.account.getHome()).then();
        } else {
            this.router.navigate(['/complete-pg-email-otp']).then();
        }
    }
}

@Component({
    selector: 'app-login-modal',
    templateUrl: './login.component.html',
})
export class LoginComponent extends LoginComponentBase {
    password: string;
    rememberMe = true;
    username: string;

    constructor(
        eventManager: JhiEventManager,
        stateStorageService: StateStorageService,
        router: Router,
        private loginService: LoginService,
    ) { super(eventManager, stateStorageService, router); }

    login() {
        this.loginService.login(new Credentials(this.username, this.password, this.rememberMe, true))
            .then(loginResult => this.handleSuccess(loginResult));
    }
}
