import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {TranslateModule} from '@ngx-translate/core';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {UiSwitchModule} from 'ngx-ui-switch';
import {RouterModule} from '@angular/router';

@NgModule({
    exports: [
        CommonModule,
        InfiniteScrollModule,
        FontAwesomeModule,
        FormsModule,
        NgbModule,
        ReactiveFormsModule,
        RouterModule,
        TranslateModule,
        UiSwitchModule
    ]
})
export class SharedLibsModule {}
