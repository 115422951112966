import {Route} from '@angular/router';

import {ActivateComponent} from './activate.component';

export const activateRoute: Route = {
    path: 'activate',
    component: ActivateComponent,
    data: {
        privileges: [],
        pageTitle: 'activate.title'
    }
};
