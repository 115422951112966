import {Injectable, Optional, SecurityContext} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {DomSanitizer} from "@angular/platform-browser";

export type AppAlertType = 'success' | 'danger' | 'warning' | 'info';

export interface AppAlert {
    id?: number;
    type: AppAlertType;
    icon?: string;
    msg: string | any;
    params?: any;
    timeout?: number;
    toast?: boolean;
    position?: string;
    scoped?: boolean;
    close?: (alerts: AppAlert[]) => void;
}

@Injectable({
    providedIn: 'root'
})
export class AppAlertService {
    private alertId: number;
    private alerts: AppAlert[];
    private timeout: number;
    private toast: boolean;
    private i18nEnabled: boolean;

    constructor(private sanitizer: DomSanitizer, @Optional() private translateService: TranslateService) {
        this.toast = true;
        this.i18nEnabled = true;
        this.alertId = 0; // unique id for each alert. Starts from 0.
        this.alerts = [];
        this.timeout = 10000;
    }

    clear() {
        this.alerts.splice(0, this.alerts.length);
    }

    get(): AppAlert[] {
        return this.alerts;
    }

    success(msg: string, params?: any, position?: string): AppAlert {
        return this.addAlert(
            {
                type: 'success',
                icon: 'check',
                msg,
                params,
                timeout: this.timeout,
                toast: this.isToast(),
                position
            },
            []
        );
    }

    error(msg: string, params?: any, position?: string): AppAlert {
        return this.addAlert(
            {
                type: 'danger',
                icon: 'exclamation-triangle',
                msg,
                params,
                timeout: this.timeout,
                toast: this.isToast(),
                position
            },
            []
        );
    }

    warning(msg: string, params?: any, position?: string): AppAlert {
        return this.addAlert(
            {
                type: 'warning',
                icon: 'exclamation',
                msg,
                params,
                timeout: this.timeout,
                toast: this.isToast(),
                position
            },
            []
        );
    }

    info(msg: string, params?: any, position?: string): AppAlert {
        return this.addAlert(
            {
                type: 'info',
                icon: 'info',
                msg,
                params,
                timeout: this.timeout,
                toast: this.isToast(),
                position
            },
            []
        );
    }

    addAlert(alertOptions: AppAlert, extAlerts: AppAlert[]): AppAlert {
        alertOptions.id = this.alertId++;
        if (typeof alertOptions.msg === 'object' && alertOptions.msg !== null) {
            if (alertOptions.msg.title && alertOptions.msg.details)
                alertOptions.msg = `${alertOptions.msg.title}\n${alertOptions.msg.details}`;
            else if (alertOptions.msg.title) alertOptions.msg = alertOptions.msg.title;
            else if (alertOptions.msg.details) alertOptions.msg = alertOptions.msg.details;
            else alertOptions.msg = JSON.stringify(alertOptions.msg);
        } else if (this.i18nEnabled && alertOptions.msg) {
            alertOptions.msg = this.translateService.instant(alertOptions.msg, alertOptions.params);
        }
        const alert = this.factory(alertOptions);
        if (alertOptions.timeout && alertOptions.timeout > 0) {
            setTimeout(() => {
                this.closeAlert(alertOptions.id, extAlerts);
            }, alertOptions.timeout);
        }
        return alert;
    }

    closeAlert(id: number, extAlerts?: AppAlert[]): AppAlert[] {
        const thisAlerts: AppAlert[] = extAlerts && extAlerts.length > 0 ? extAlerts : this.alerts;
        return this.closeAlertByIndex(thisAlerts.map(e => e.id).indexOf(id), thisAlerts);
    }

    closeAlertByIndex(index: number, thisAlerts: AppAlert[]): AppAlert[] {
        return thisAlerts.splice(index, 1);
    }

    isToast(): boolean {
        return this.toast;
    }

    private factory(alertOptions: AppAlert): AppAlert {
        const alert: AppAlert = {
            type: alertOptions.type,
            icon: alertOptions.icon ? alertOptions.icon : 'info',
            msg: this.sanitizer.sanitize(SecurityContext.HTML, alertOptions.msg),
            id: alertOptions.id,
            timeout: alertOptions.timeout,
            toast: alertOptions.toast,
            position: alertOptions.position ? alertOptions.position : 'top right',
            scoped: alertOptions.scoped,
            close: (alerts: AppAlert[]) => {
                return this.closeAlert(alertOptions.id, alerts);
            }
        };
        if (!alert.scoped) {
            this.alerts.push(alert);
        }
        return alert;
    }
}
