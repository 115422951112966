import {Directive, EventEmitter, Input, Output} from '@angular/core';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';

@Directive({
    selector: '[appSort]'
})
export class SortDirective {
    @Input() predicate: string;
    @Input() ascending: boolean;
    @Input() callback: () => {};

    @Output() predicateChange: EventEmitter<string> = new EventEmitter();
    @Output() ascendingChange: EventEmitter<boolean> = new EventEmitter();

    activeIconComponent: FaIconComponent;
    constructor() {}

    sort(field: string) {
        this.ascending = field !== this.predicate ? true : !this.ascending;
        this.predicate = field;
        this.predicateChange.emit(field);
        this.ascendingChange.emit(this.ascending);
        this.callback();
    }
}
