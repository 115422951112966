<main role="main" class="ml-0">
    <div class="card mt-3">
        <div class="card-body pt-0 ">
            <h2 appTranslate="password.title" [translateValues]="{username: account.login}" *ngIf="account">Password for
                [<b>{{account.login}}</b>]</h2>

            <div class="alert alert-success" *ngIf="success" appTranslate="password.messages.success">
                <strong>Password changed!</strong>
            </div>
            <div class="alert alert-danger" *ngIf="error" appTranslate="password.messages.error">
                <strong>An error has occurred!</strong> The password could not be changed.
            </div>

            <div class="alert alert-danger" *ngIf="doNotMatch" appTranslate="global.messages.error.dontmatch">
                The password and its confirmation do not match!
            </div>

            <form name="form" role="form" (ngSubmit)="changePassword()" #passwordForm="ngForm">

                <div class="form-group">
                    <label class="form-control-label" for="currentPassword" appTranslate="global.form.currentpassword">Current
                        password</label>
                    <input type="password" class="form-control" id="currentPassword" name="currentPassword"
                           autocomplete="current-password"
                           #currentPasswordInput="ngModel"
                           placeholder="{{'global.form.currentpassword.placeholder' | translate}}"
                           [(ngModel)]="currentPassword" required>
                    <div *ngIf="currentPasswordInput.dirty && currentPasswordInput.invalid">
                        <small class="form-text text-danger"
                               *ngIf="currentPasswordInput.errors.required"
                               appTranslate="global.messages.validate.newpassword.required">
                            Your password is required.
                        </small>
                    </div>
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="newPassword" appTranslate="global.form.newpassword">New
                        password</label>
                    <input type="password" class="form-control" id="newPassword" name="newPassword"
                           #newPasswordInput="ngModel"
                           autocomplete="new-password"
                           placeholder="{{'global.form.newpassword.placeholder' | translate}}"
                           [(ngModel)]="newPassword" minlength=4 maxlength=50 required>
                    <div *ngIf="newPasswordInput.dirty && newPasswordInput.invalid">
                        <small class="form-text text-danger"
                               *ngIf="newPasswordInput.errors.required"
                               appTranslate="global.messages.validate.newpassword.required">
                            Your password is required.
                        </small>
                        <small class="form-text text-danger"
                               *ngIf="newPasswordInput.errors.minlength"
                               appTranslate="global.messages.validate.newpassword.minlength">
                            Your password is required to be at least 4 characters.
                        </small>
                        <small class="form-text text-danger"
                               *ngIf="newPasswordInput.errors.maxlength"
                               appTranslate="global.messages.validate.newpassword.maxlength">
                            Your password cannot be longer than 50 characters.
                        </small>
                    </div>
                    <app-password-strength-bar [passwordToCheck]="newPassword"></app-password-strength-bar>
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="confirmPassword" appTranslate="global.form.confirmpassword">New
                        password confirmation</label>
                    <input type="password" class="form-control" id="confirmPassword" name="confirmPassword"
                           autocomplete="new-password"

                           #confirmPasswordInput="ngModel"
                           placeholder="{{'global.form.confirmpassword.placeholder' | translate}}"
                           [(ngModel)]="confirmPassword" minlength=4 maxlength=50 required>
                    <div *ngIf="confirmPasswordInput.dirty && confirmPasswordInput.invalid">
                        <small class="form-text text-danger"
                               *ngIf="confirmPasswordInput.errors.required"
                               appTranslate="global.messages.validate.confirmpassword.required">
                            Your confirmation password is required.
                        </small>
                        <small class="form-text text-danger"
                               *ngIf="confirmPasswordInput.errors.minlength"
                               appTranslate="global.messages.validate.confirmpassword.minlength">
                            Your confirmation password is required to be at least 4 characters.
                        </small>
                        <small class="form-text text-danger"
                               *ngIf="confirmPasswordInput.errors.maxlength"
                               appTranslate="global.messages.validate.confirmpassword.maxlength">
                            Your confirmation password cannot be longer than 50 characters.
                        </small>
                    </div>
                </div>

                <button type="submit" [disabled]="passwordForm.form.invalid" class="btn btn-primary"
                        appTranslate="password.form.button">Save
                </button>
            </form>
        </div>
    </div>
</main>

