import {Component, Input, OnInit} from '@angular/core';

const classTrue = 'fa fa-lg fa-check text-success';
const classFalse = 'fa fa-lg fa-times text-danger';
const classBadgeTrue = 'badge badge-success';
const classBadgeFalse = 'badge badge-danger';

@Component({
    selector: 'app-boolean',
    template: `
        <span [ngClass]="value ? classTrue : classFalse" [innerHtml]="value ? textTrue : textFalse"> </span>
    `
})
export class BooleanComponent implements OnInit {
    /**
     * the boolean input value
     */
    @Input() value: boolean;

    /**
     * the class(es) (space separated) that will be applied if value is true
     */
    @Input() classTrue: string;

    /**
     * the class(es) (space separated) that will be applied if the input value is false
     */
    @Input() classFalse: string;

    /**
     * the text that will be displayed if the input value is true
     */
    @Input() textTrue: string;

    /**
     * the text that will be displayed if the input value is false
     */
    @Input() textFalse: string;

    constructor() {}

    ngOnInit() {
        if (this.textTrue === undefined) {
            if (this.classTrue === undefined) {
                this.classTrue = classTrue;
            }
        } else {
            if (this.classTrue === undefined) {
                this.classTrue = classBadgeTrue;
            }
        }

        if (this.textFalse === undefined) {
            if (this.classFalse === undefined) {
                this.classFalse = classFalse;
            }
        } else {
            if (this.classFalse === undefined) {
                this.classFalse = classBadgeFalse;
            }
        }
    }
}
