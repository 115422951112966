import {IFilter} from '@core/model/api/filter.model';
import {IRecordType} from '@core/model/api/record-type.model';

export interface IConfigurationVersion {
    id?: number;
    name?: string;
    development?: boolean;
    filters?: IFilter[];
    recordTypes?: IRecordType[];
    projectName?: string;
    projectId?: number;
}

export class ConfigurationVersion implements IConfigurationVersion {
    constructor(
        public id?: number,
        public name?: string,
        public development?: boolean,
        public filters?: IFilter[],
        public recordTypes?: IRecordType[],
        public projectName?: string,
        public projectId?: number
    ) {
        this.development = this.development || false;
    }
}
