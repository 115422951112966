import {Component, OnDestroy, OnInit} from '@angular/core';
import {AppAlertService} from '@core/service/util/app-alert.service';

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
})
export class JhiAlertComponent implements OnInit, OnDestroy {
    alerts: any[];

    constructor(private alertService: AppAlertService) {}

    ngOnInit() {
        this.alerts = this.alertService.get();
    }

    ngOnDestroy() {
        this.alerts = [];
    }
}
