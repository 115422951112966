import {Injectable} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router} from '@angular/router';
import {BehaviorSubject, concat, Observable, of} from 'rxjs';
import {distinct, filter, first, flatMap, toArray} from 'rxjs/operators';
import {BreadcrumbsResolver} from './breadcrumbs.resolver';
import {IBreadcrumb} from '@shared/component/breadcrumb/breadcrumb';

@Injectable({ providedIn: 'root' })
export class BreadcrumbsService {
    private breadcrumbsSubject = new BehaviorSubject<IBreadcrumb[]>([]);

    constructor(private router: Router, private route: ActivatedRoute, private resolver: BreadcrumbsResolver) {
        this.emitBreadcrumbs();
        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((navigationEndEvent: NavigationEnd) => {
            this.emitBreadcrumbs();
        });
    }

    get breadcrumbs(): Observable<IBreadcrumb[]> {
        return this.breadcrumbsSubject;
    }

    private emitBreadcrumbs() {
        this.resolveBreadcrumbs(this.router.routerState.snapshot.root)
            .pipe(
                flatMap(breadCrumb => breadCrumb),
                distinct(breadCrumb => breadCrumb.text),
                toArray(),
                flatMap(breadCrumbs => of(breadCrumbs))
            )
            .subscribe(breadCrumbs => {
                this.breadcrumbsSubject.next(breadCrumbs);
            });
    }

    private resolveBreadcrumbs(route: ActivatedRouteSnapshot): Observable<IBreadcrumb[]> {
        let breadcrumbs: Observable<IBreadcrumb[]>;

        const data = route.routeConfig && route.routeConfig.data;

        breadcrumbs = data && data.breadcrumbs ? this.resolver.resolve(route, this.router.routerState.snapshot).pipe(first()) : of([]);

        if (route.firstChild) {
            breadcrumbs = concat(breadcrumbs, this.resolveBreadcrumbs(route.firstChild));
        }

        return breadcrumbs;
    }
}
