import {Component} from '@angular/core';
import {Router} from "@angular/router";
import {StateStorageService} from "@core/service/auth/state-storage.service";
import {LoginService} from "@core/service/login.service";
import {JhiEventManager} from "@core/service/util";
// noinspection ES6PreferShortImport
import {LoginComponentBase} from "../login/login.component";

@Component({
    selector: 'app-login-modal',
    templateUrl: './complete-pg-email-otp.component.html',
})
export class CompletePgEmailOtpComponent extends LoginComponentBase {
    otp: string;

    constructor(
        eventManager: JhiEventManager,
        stateStorageService: StateStorageService,
        router: Router,
        private loginService: LoginService,
    ) { super(eventManager, stateStorageService, router); }

    completePgEmailOtp() //
    { this.loginService.completePgEmailOtp(this.otp).then(loginResult => this.handleSuccess(loginResult)); }

    resendPgEmailOtp() { this.loginService.resendPgEmailOtp().then(); }
}
