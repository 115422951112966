import {Component, ContentChildren, Directive, Input, OnInit, Renderer2} from '@angular/core';
import {AccountService} from "@core/service/auth/account.service";
import {IconProp} from '@fortawesome/fontawesome-svg-core';

@Directive({selector: 'app-sidebar-item'})
export class AppSidebarItem {
    @Input() public title: string;
    @Input() public icon: IconProp;
    @Input() public routerLink: string;
    @Input() public disabled = false;
    @Input() public hasAnyAuthority: string | string[];
}

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
    @ContentChildren(AppSidebarItem) items: AppSidebarItem[];
    collapsed = false;
    homeLink: string = '/';

    constructor(
        private renderer: Renderer2,
        private accountService: AccountService,
    ) {}

    ngOnInit() { this.accountService.identity().then(a => this.homeLink = a ? `/${a.getHome().join('/')}` : '/'); }

    collapse() {
        if (this.collapsed) this.renderer.removeClass(document.body, 'collapsed');
        else this.renderer.addClass(document.body, 'collapsed');
        this.collapsed = !this.collapsed;
    }
}
