import {Injectable, isDevMode} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, ParamMap, Router, RouterStateSnapshot} from '@angular/router';
import {Account} from '@core/model/api/account.model.js';

import {AccountService} from '@core/service/auth/account.service';
import {StateStorageService} from '@core/service/auth/state-storage.service';

@Injectable({providedIn: 'root'})
export class UserRouteAccessService implements CanActivate {
    constructor(
        private router: Router,
        private accountService: AccountService,
        private stateStorageService: StateStorageService,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> {
        let privileges: string[] = !Array.isArray(route.data.privileges) && route.data.privileges
            ? [route.data.privileges] : route.data.privileges;
        if (!privileges?.length) return true;
        console.log(`canActivate: ${state.url} need: ${privileges}`);
        return this.accountService.identity().then((account: Account) => {
            if (!account) {
                this.stateStorageService.storeUrl(state.url);
                this.router.navigate(['accessdenied'])
                    .then(() => { if (!account) this.router.navigate(['login']).then(); });
                return false;
            }
            return this.checkYourPrivilege(route, privileges, account,
                () => this.accountService.identity(true).then(account => {
                    this.accountService.authenticate(account);
                    return account;
                }));
        });
    }

    private checkYourPrivilege(
        route: ActivatedRouteSnapshot, privileges: string[], account?: Account | Promise<Account>,
        identityRefresher?: () => Promise<Account>,
    ): Promise<boolean> {
        return this.getHolderAuthzData(route.paramMap, account, identityRefresher).then(authzData => {
            let rv = this.accountService.checkYourPrivilege(authzData, privileges);
            if (!rv && isDevMode())
                console.error('User doesn\'t have the required privileges:', privileges.toString(),
                    'for holder', authzData?.id, authzData?.holderType?.toString(), authzData?.name);
            return rv;
        });
    }

    getHolderAuthzData(map: ParamMap, account?: Account | Promise<Account>, identityRefresher?: () => Promise<Account>) {
        return this.accountService.getHolderAuthzData(Number(null
            ?? map.get('stageId')
            ?? map.get('projectId')
            ?? map.get('projectGroupId')
            ?? '0'
        ), account, identityRefresher);
    }
}
