import {Routes} from '@angular/router';
import {activateRoute} from './activate/activate.route';
import {completePgEmailOtpRoute} from "./complete-pg-email-otp/complete-pg-email-otp.route";
import {loginRoute} from './login/login.route';
import {passwordResetFinishRoute} from './password-reset/finish/password-reset-finish.route';
import {passwordResetInitRoute} from './password-reset/init/password-reset-init.route';

const ACCOUNT_PUBLIC_ROUTES = [
    activateRoute,
    completePgEmailOtpRoute,
    loginRoute,
    passwordResetFinishRoute,
    passwordResetInitRoute,
];

export const accountPublicState: Routes = ACCOUNT_PUBLIC_ROUTES;
