import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';

import {SharedModule} from '@shared/shared.module';

import {
    ActivateComponent,
    CompletePgEmailOtpComponent,
    LoginComponent,
    PasswordComponent,
    PasswordResetFinishComponent,
    PasswordResetInitComponent,
    SettingsComponent,
} from './index';

@NgModule({
    imports: [SharedModule],
    declarations: [
        ActivateComponent,
        CompletePgEmailOtpComponent,
        LoginComponent,
        PasswordComponent,
        PasswordResetFinishComponent,
        PasswordResetInitComponent,
        SettingsComponent,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class MonoAccountModule {}
