import {
    AppAlertErrorComponent,
    AppSidebarItem,
    BooleanComponent,
    ItemCountComponent,
    JhiAlertComponent,
    JhiBreadcrumbComponent,
    JhiFormSubArrayComponent,
    NavbarComponent,
    PasswordStrengthBarComponent,
    SidebarComponent
} from '@shared/component';
import {
    AutofocusDirective,
    DisableControlDirective,
    HasPrivilegeDirective,
    MaxbytesValidatorDirective,
    MaxValidatorDirective,
    MinbytesValidatorDirective,
    MinValidatorDirective,
    SortByDirective,
    SortDirective,
    TranslateDirective
} from '@shared/directive';
import {
    CapitalizePipe,
    DateAsWeLikeIt,
    ExcludeArrayPipe,
    FilterPipe,
    FindLanguageFromKeyPipe,
    KeysPipe, LineBreakPipe,
    OrderByPipe,
    PureFilterPipe,
    TruncateCharactersPipe,
    TruncateWordsPipe
} from '@shared/pipe';

export const PIPES = [
    CapitalizePipe,
    DateAsWeLikeIt,
    FilterPipe,
    FindLanguageFromKeyPipe,
    KeysPipe,
    LineBreakPipe,
    OrderByPipe,
    PureFilterPipe,
    TruncateCharactersPipe,
    TruncateWordsPipe,
    ExcludeArrayPipe
];

export const DIRECTIVES = [
    AutofocusDirective,
    DisableControlDirective,
    HasPrivilegeDirective,
    MaxValidatorDirective,
    MinValidatorDirective,
    MaxbytesValidatorDirective,
    MinbytesValidatorDirective,
    SortDirective,
    SortByDirective,
    TranslateDirective
];

export const COMPONENTS = [
    ItemCountComponent,
    BooleanComponent,
    JhiAlertComponent,
    AppAlertErrorComponent,
    JhiBreadcrumbComponent,
    JhiFormSubArrayComponent,
    SidebarComponent,
    AppSidebarItem,
    NavbarComponent,
    PasswordStrengthBarComponent
];
