<nav class="navbar navbar-expand-md navbar-dark fixed-top app-navbar">
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
        <div class="navbar-collapse collapse" id="navbarCollapse" [ngbCollapse]="isNavbarCollapsed" [ngSwitch]="isAuthenticated()">
            <div class="navbar-version ml-auto">{{version}}</div>
            <ul class="navbar-nav">
                <li ngbDropdown class="nav-item dropdown pointer" *ngIf="languages && languages.length > 1">
                    <a class="nav-link dropdown-toggle" ngbDropdownToggle href="javascript:void(0);" id="languagesnavBarDropdown">
                        <span>
                            <fa-icon icon="flag"></fa-icon>
                            <span appTranslate="global.menu.language">Language</span>
                        </span>
                    </a>
                    <ul class="dropdown-menu" ngbDropdownMenu aria-labelledby="languagesnavBarDropdown">
                        <li *ngFor="let language of languages">
                            <a class="dropdown-item" [ngClass]="{active: language === currentLanguage }"  href="javascript:void(0);" (click)="changeLanguage(language);collapseNavbar();">{{language | findLanguageFromKey}}</a>
                        </li>
                    </ul>
                </li>
                <li ngbDropdown class="nav-item dropdown pointer" placement="bottom-right" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                    <a class="nav-link dropdown-toggle" ngbDropdownToggle href="javascript:void(0);" id="account-menu">
                      <span *ngIf="!getImageUrl()">
                        <fa-icon icon="user"></fa-icon>
                        <span appTranslate="global.menu.account.main">
                          Account
                        </span>
                      </span>
                      <span *ngIf="getImageUrl()">
                          <img [src]="getImageUrl()" class="profile-image rounded-circle" alt="Avatar">
                      </span>
                    </a>
                    <ul class="dropdown-menu" ngbDropdownMenu aria-labelledby="account-menu">
                        <li *ngIf="isAuthenticated() && !currentAccount?.protectedUser">
                            <a class="dropdown-item" routerLink="/settings" routerLinkActive="active" (click)="collapseNavbar()">
                                <fa-icon icon="wrench" fixedWidth="true"></fa-icon>
                                <span appTranslate="global.menu.account.settings">Settings</span>
                            </a>
                        </li>
                        <li *ngSwitchCase="true">
                            <a class="dropdown-item" routerLink="/password" routerLinkActive="active" (click)="collapseNavbar()">
                                <fa-icon icon="clock" fixedWidth="true"></fa-icon>
                                <span appTranslate="global.menu.account.password">Password</span>
                            </a>
                        </li>
                        <li *ngSwitchCase="true">
                            <a class="dropdown-item" (click)="logout()" id="logout">
                                <fa-icon icon="sign-out-alt" fixedWidth="true"></fa-icon>
                                <span appTranslate="global.menu.account.logout">Sign out</span>
                            </a>
                        </li>
                        <li *ngSwitchCase="false">
                            <a class="dropdown-item" (click)="login()" id="login">
                                <fa-icon icon="sign-in-alt" fixedWidth="true"></fa-icon>
                                <span appTranslate="global.menu.account.login">Sign in</span>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
</nav>
