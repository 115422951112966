import {Directive, forwardRef, Input} from '@angular/core';
import {FormControl, NG_VALIDATORS} from '@angular/forms';

import {numberOfBytes} from './number-of-bytes';

@Directive({
    selector: '[appMinbytes][ngModel]',
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    providers: [{ provide: NG_VALIDATORS, useExisting: forwardRef(() => MinbytesValidatorDirective), multi: true }]
})
export class MinbytesValidatorDirective {
    @Input() appMinbytes: number;

    constructor() {}

    validate(c: FormControl) {
        return c.value && numberOfBytes(c.value) < this.appMinbytes
            ? {
                  minbytes: {
                      valid: false
                  }
              }
            : null;
    }
}
