import {LOCALE_ID, NgModule} from '@angular/core';
import {CommonModule, DatePipe, registerLocaleData} from '@angular/common';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {FaIconLibrary} from '@fortawesome/angular-fontawesome';

import {MissingTranslationHandler, TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {MissingTranslationHandlerImpl} from '@core/language/missing-translation.config';
import {Title} from '@angular/platform-browser';
import {NgbDateMomentAdapter} from '@shared/util/datepicker-adapter';
import {NgbDateAdapter, NgbDatepickerConfig} from '@ng-bootstrap/ng-bootstrap';
import {fontAwesomeIcons} from './icons/font-awesome-icons';
import * as moment from 'moment';
import locale from '@angular/common/locales/en';
import {ErrorComponent} from '@core/component/error/error.component';
import {PageRibbonComponent} from '@core/component/profiles/page-ribbon.component';
import {FooterComponent} from '@core/component/footer/footer.component';

export function translatePartialLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, 'i18n/', `.json`);
}

export function missingTranslationHandler() {
    return new MissingTranslationHandlerImpl();
}

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: translatePartialLoader,
                deps: [HttpClient]
            },
            missingTranslationHandler: {
                provide: MissingTranslationHandler,
                useFactory: missingTranslationHandler
            }
        })
    ],
    declarations: [ErrorComponent, PageRibbonComponent, FooterComponent],
    exports: [ErrorComponent, PageRibbonComponent, FooterComponent],
    providers: [
        Title,
        {
            provide: LOCALE_ID,
            useValue: 'en'
        },
        { provide: NgbDateAdapter, useClass: NgbDateMomentAdapter },
        DatePipe
    ]
})
export class CoreModule {
    constructor(library: FaIconLibrary, private dpConfig: NgbDatepickerConfig) {
        registerLocaleData(locale);
        library.addIcons(...fontAwesomeIcons);
        this.dpConfig.minDate = { year: moment().year() - 100, month: 1, day: 1 };
    }
}
